import styled from "styled-components";

export const MajorDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  width: 50%;
  margin-bottom: 5%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;

export const MajorImg = styled.img`
  width: 50%;
  @media screen and (max-width: 800px) {
    width: 40%;
  }
`;

export const MajorDetail = styled.div`
  margin-left: 3%;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    margin-left: 5%;
    width: 40%;
  }
`;

export const MajorDetailTitle = styled.p`
  margin-bottom: 0px;
  font-size: 15px;
  @media screen and (max-width: 1600px) {
    font-size: 12px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 10px;
  }
  @media screen and (max-width: 900px) {
    font-size: 8px;
  }
  @media screen and (max-width: 800px) {
    font-size: 2vw;
  }
`;

export const MajorDetailDesc = styled.p`
  font-size: 25px;
  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }
  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }
  @media screen and (max-width: 900px) {
    font-size: 12px;
  }

  @media screen and (max-width: 800px) {
    font-size: 2.5vw;
  }
`;

export const MajorDetailDiv = styled.div`
  height: 90px;

  @media screen and (max-width: 1600px) {
    height: 70px;
  }
  @media screen and (max-width: 1300px) {
    height: 60px;
  }

  @media screen and (max-width: 1200px) {
    height: 50px;
  }

  @media screen and (max-width: 900px) {
    height: 40px;
  }
  @media screen and (max-width: 800px) {
    height: auto;
  }
`;
