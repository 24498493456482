import {
  About,
  AboutBackground,
  AboutHeader,
  AboutImg,
  AboutMobile,
  AboutText,
  BuildingImg,
  BuildingMobileImg,
  Div,
  DivMap,
  FooterDiv,
  LineImg,
  MajorProjectDiv,
  MajorProjectTitle,
  MapImg,
  MapMobileImg,
  MapSmallMobileImg,
  WhyUs,
  WhyUsDiv,
  WhyUsDivText,
  WhyUsLearnButton,
} from "./AboutUsElement";
import { useNavigate } from "react-router-dom";
import Image from "../../assets/images/AboutUsImage.png";
import Line from "../../assets/images/Line.png";
import Building from "../../assets/images/twin-building.png";
import BuildingMobile from '../../assets/images/BuildingMobile.png';
import Map from "../../assets/images/map.png";
import MapMobile from '../../assets/images/MapMobile.png';
import MapSmallMobile from '../../assets/images/MapSmallMobile.png';
import MajorProject from "../../components/MajorProject";
import major1 from "../../assets/images/villas.png";
import major2 from "../../assets/images/tower.png";
import major3 from "../../assets/images/WhatsApp Image 2021-02-24 at 14.32.24.png";
import major4 from "../../assets/images/Bedroom_02-scaled.png";
import major5 from "../../assets/images/WhatsApp Image 2021-03-01 at 17.09.00 (2).png";
import major6 from "../../assets/images/Misk 1.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const AboutUs = () => {
  const navigate = useNavigate();
  const goTo = (to) => {
    navigate(to);
  };

  return (
    <>
      <Navbar isWhite={true} />
      <AboutBackground>
        <AboutImg src={Image} />
        <LineImg src={Line} />
        <About>
          ABOUT <br /> US
        </About>
        <AboutMobile>
          ABOUT US
        </AboutMobile>
        <AboutText>
          Osoul Properties & OTS are a full real estate <br /> services
          companies that excel in acquiring, developing and supplying building
          material to a wide variety of assets. Industries including real estate
          developers, hotels, governmental entities, educational institutions,
          banking, retail and individuals
        </AboutText>
      </AboutBackground>
      <AboutHeader>
        <BuildingImg src={Building} />
        <BuildingMobileImg src={BuildingMobile} />
        <WhyUsDiv>
          <WhyUs>Why us?</WhyUs>
          <WhyUsDivText>
            Osoul Properties is a leading international property company
            specializing in connecting international and regional buyers with
            select properties in Dubai. Our team of super agents are incredibly
            experienced, knowledgeable, and helpful.
          </WhyUsDivText>
          <WhyUsLearnButton
            onClick={() => {
              goTo("/whyUs");
            }}
          >
            Learn More
          </WhyUsLearnButton>
        </WhyUsDiv>
      </AboutHeader>
      <AboutBackground>
        <MapImg src={Map}>
          <DivMap>SITEMAP</DivMap>
        </MapImg>
        <MapMobileImg src={MapMobile}>
          <DivMap>SITEMAP</DivMap>
        </MapMobileImg>
        <MapSmallMobileImg src={MapSmallMobile}>
          <DivMap>SITEMAP</DivMap>
        </MapSmallMobileImg>
      </AboutBackground>
      <MajorProjectTitle>
        MAJOR <br /> PROJECTS IN DUABI
      </MajorProjectTitle>
      <MajorProjectDiv>
        <MajorProject image={major1} />
        <MajorProject image={major2} />
        <MajorProject image={major3} />
        <MajorProject image={major4} />
        <MajorProject image={major5} />
        <MajorProject image={major6} />
      </MajorProjectDiv>
      <Footer />
    </>
  );
};
export default AboutUs;
