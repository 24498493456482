import styled from "styled-components";

export const StatusContainer = styled.nav`
  width: 100%;
  height: 400px;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const StatusNews = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(100%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  z-index: -1;
`;

export const NewsTitle = styled.h1`
  font-size: 150%;
  font-weight: 500;
  padding-top: 5%;
  padding-left: 10%;
`;

export const NewsDate = styled.p`
  font-size: 100%;
  font-weight: 400;
  padding-left: 10%;
`;

export const NewsContent = styled.p`
  width: 100%;
  font-size: 100%;
  font-weight: 100;
  padding-left: 10%;
  padding-bottom: 5%;
`;

export const NewsImgContainer = styled.img`
  padding-top: 10%;
  padding-bottom: 10%;
`;

export const NewsButton = styled.button`
  margin-top: 5%;
  margin-bottom: 5%;
  background-color: #740000;
  color: white;
  border: 0;
  border-radius: 5px;
  padding-left: 5%;
  padding-right: 5%;
  padding-top: 1%;
  padding-bottom: 1%;
  &:hover {
    color: #740000;
    background-color: white;
    border: 2px solid #740000;
  }
`;

export const RowNewsInfo = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
  }
`;
