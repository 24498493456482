import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../../reducers/userSlice.js";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as mdb from "mdb-ui-kit";
import "mdb-ui-kit/css/mdb.min.css";
import { useNavigate } from "react-router-dom";
import { Navigation, Pagination, Scrollbar, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/less";
import "swiper/less/navigation";
import "swiper/less/pagination";
import { useSwiper } from "swiper/react";
import Aos from "aos";
import "aos/dist/aos.css";
import Title from "react-vanilla-tilt";
import {
  LandingCarouselBackground,
  LandingCarouselTitle,
  LandingCarouselDescription,
  CarouselBoxLeft,
  CarouselBoxRight,
  CarouselDesktop,
  CarouselMobile,
} from "./LandingCarouselElements";
import reitrackLogo from "../../assets/BlueIsland.png";
import CarouselBox from "./CarouselBox.js";

const LandingCarousel = (props) => {
  const [index, setIndex] = useState(1);
  const [indexMobile, setIndexMobile] = useState(0);
  const navigate = useNavigate();
  useState(() => {
    Aos.init({ duration: 2000 });
  }, []);

  return (
    <LandingCarouselBackground
      style={{ paddingTop: "10%", paddingBottom: "10%" }}
    >
      <CarouselDesktop>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "stretch",
          }}
        >
          <Swiper
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "stretch",
              width: "100%",
            }}
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={3}
            navigation
            centeredSlides={false}
            grabCursor={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideNextTransitionEnd={(e) => {
              setIndex(e.activeIndex + 1);
              console.log(e.activeIndex);
            }}
            onSlidePrevTransitionStart={(e) => {
              setIndex(e.activeIndex + 1);
              console.log(e.activeIndex);
            }}
          >
            <SwiperSlide style={{ paddingTop: "5%", paddingBottom: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-up"
              >
                {index === 0 && <CarouselBox active={true} />}
                {index != 0 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide slot="container-start" style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-down"
              >
                {index === 1 && <CarouselBox active={true} />}
                {index != 1 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-up"
              >
                {index === 2 && <CarouselBox active={true} />}
                {index != 2 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {index === 3 && <CarouselBox active={true} />}
                {index != 3 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {index === 4 && <CarouselBox active={true} />}
                {index != 4 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {index === 5 && <CarouselBox active={true} />}
                {index != 5 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {index === 6 && <CarouselBox active={true} />}
                {index != 6 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {index === 7 && <CarouselBox active={true} />}
                {index != 7 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
          </Swiper>
        </Container>
      </CarouselDesktop>
      <CarouselMobile>
        <Container
          style={{
            display: "flex",
            flexDirection: "row",
            flexWrap: "nowrap",
            justifyContent: "center",
            alignItems: "center",
            alignContent: "stretch",
          }}
        >
          <Swiper
            style={{
              display: "flex",
              flexDirection: "row",
              flexWrap: "nowrap",
              justifyContent: "center",
              alignItems: "center",
              alignContent: "stretch",
              width: "100%",
            }}
            // install Swiper modules
            modules={[Navigation, Pagination, Scrollbar, A11y]}
            spaceBetween={10}
            slidesPerView={1}
            navigation
            centeredSlides={false}
            grabCursor={true}
            pagination={{ clickable: true }}
            scrollbar={{ draggable: true }}
            onSlideNextTransitionEnd={(e) => {
              setIndexMobile(e.activeIndex + 0);
              console.log(e.activeIndex);
            }}
            onSlidePrevTransitionStart={(e) => {
              setIndexMobile(e.activeIndex + 0);
              console.log(e.activeIndex);
            }}
          >
            <SwiperSlide style={{ paddingTop: "5%", paddingBottom: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-up"
              >
                {indexMobile === 0 && <CarouselBox active={true} />}
                {indexMobile != 0 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide slot="container-start" style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-down"
              >
                {indexMobile === 1 && <CarouselBox active={true} />}
                {indexMobile != 1 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
                data-aos="fade-up"
              >
                {indexMobile === 2 && <CarouselBox active={true} />}
                {indexMobile != 2 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {indexMobile === 3 && <CarouselBox active={true} />}
                {indexMobile != 3 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {indexMobile === 4 && <CarouselBox active={true} />}
                {indexMobile != 4 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {indexMobile === 5 && <CarouselBox active={true} />}
                {indexMobile != 5 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {indexMobile === 6 && <CarouselBox active={true} />}
                {indexMobile != 6 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
            <SwiperSlide style={{ paddingTop: "5%" }}>
              <Title
                style={{
                  marginTop: "0%",
                  borderRadius: "10px",
                }}
              >
                {indexMobile === 7 && <CarouselBox active={true} />}
                {indexMobile != 7 && <CarouselBox active={false} />}
              </Title>
            </SwiperSlide>
          </Swiper>
        </Container>
      </CarouselMobile>
    </LandingCarouselBackground>
  );
};

export default LandingCarousel;
