import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Modal from "react-bootstrap/Modal";
import Navbar from "../../components/Navbar";
import featuredHomeImg from "../../assets/featuredHome.png";
import {
  HomeBackground,
  HomeImg,
  HomeTitle,
  FeatureRedButton,
  FeatureRedButtonsContainer,
  FeatureSelect,
  FeaturedImg,
  FeaturedSubTitle,
  ReadMoreSubTitle,
  HomeContainerResponsive,
  FeatureRedButtonsContainerMobile,
  RowImagesBottom,
  MobileModal,
} from "./FeaturedElements";
import img1 from "../../assets/cityWalkFeatured.png";
import Footer from "../../components/Footer";
import PropertiesCloseUp from "../PropertiesCloseUp";

const FeaturedPage = () => {
  const [showModel, setShowModel] = useState(false);
  const [titleState, setTitleState] = useState("");
  const [textState, setTextState] = useState("");
  const [sourceState, setSourceState] = useState("");

  const navigate = useNavigate();
  const goTo = (to) => {
    navigate(to);
  };
  function MyVerticallyCenteredModal(props) {
    return (
      <MobileModal>
        <Modal {...props} style={{ backgroundColor: "rgba(0,0,0,0.6)" }}>
          <MobileModal>
            <PropertiesCloseUp
              setShowModel={setShowModel}
              titleState={titleState}
              textState={textState}
              sourceState={sourceState}
            />
          </MobileModal>
        </Modal>
      </MobileModal>
    );
  }

  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        <Container>
          <HomeContainerResponsive>
            <HomeImg src={featuredHomeImg}></HomeImg>
            <HomeTitle>
              Property & fasculty
              <br /> management
            </HomeTitle>
            <FeatureRedButtonsContainer>
              <FeatureSelect>
                <option style={{ backgroundColor: "white", color: "#96191e" }}>
                  Project Status
                </option>
              </FeatureSelect>
              <FeatureSelect>
                <option style={{ backgroundColor: "white", color: "#96191e" }}>
                  Project Type
                </option>
              </FeatureSelect>
              <FeatureSelect>
                <option style={{ backgroundColor: "white", color: "#96191e" }}>
                  Location
                </option>
              </FeatureSelect>
              <FeatureSelect>
                <option style={{ backgroundColor: "white", color: "#96191e" }}>
                  Budget
                </option>
              </FeatureSelect>
              <FeatureRedButton>SEARCH</FeatureRedButton>
            </FeatureRedButtonsContainer>
          </HomeContainerResponsive>
        </Container>
        <Container>
          <FeatureRedButtonsContainerMobile>
            <FeatureSelect>
              <option style={{ backgroundColor: "white", color: "#96191e" }}>
                Project Status
              </option>
            </FeatureSelect>
            <FeatureSelect>
              <option style={{ backgroundColor: "white", color: "#96191e" }}>
                Project Type
              </option>
            </FeatureSelect>
            <FeatureSelect>
              <option style={{ backgroundColor: "white", color: "#96191e" }}>
                Location
              </option>
            </FeatureSelect>
            <FeatureSelect>
              <option style={{ backgroundColor: "white", color: "#96191e" }}>
                Budget
              </option>
            </FeatureSelect>
            <FeatureRedButton>SEARCH</FeatureRedButton>
          </FeatureRedButtonsContainerMobile>
        </Container>
        <Container style={{ paddingTop: "10%", paddingBottom: "10%" }}>
          <RowImagesBottom>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>Bluewaters island </FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>CITY WALK</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>downtown</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>Mohammed Bin Rashed City</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
          </RowImagesBottom>
          <RowImagesBottom>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>dubai creek HARBOR</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>
                  Dubai International Financial Centre
                </FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>dubai hills</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
            <Col>
              <Row>
                <FeaturedImg
                  onMouseMove={() => {
                    setShowModel(true);
                    setTitleState("DUBAI CREEK HARBOR");
                    setTextState(`Osoul Properties & OTS are one of the fastest growing facility
                    management and a leading worldwide supplier of building material, from
                    floor & wall tiles to sanitary ware, leveraging on the beneficial
                    synergistic partnerships within the group, we are dedicated to
                    offering fully integrated real estate solutions`);
                    setSourceState(img1);
                  }}
                  src={img1}
                />
                <FeaturedSubTitle>Palm Jumeirah</FeaturedSubTitle>
                <ReadMoreSubTitle
                  onClick={() => {
                    goTo("/details");
                  }}
                >
                  READ MORE
                </ReadMoreSubTitle>
              </Row>
            </Col>
          </RowImagesBottom>
        </Container>
      </HomeBackground>
      <MobileModal>
        {window.screen.width > "800" && (
          <MyVerticallyCenteredModal
            show={showModel}
            onHide={() => setShowModel(false)}
          />
        )}
      </MobileModal>

      <Footer />
    </>
  );
};

export default FeaturedPage;
