import styled from "styled-components";

export const HomeBackground = styled.nav`
  width: 100%;
  height: 30vh;
  background-color: #04062f;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  z-index: 1;
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 30vh;
  position: absolute;
  filter: brightness(100%);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  color: white;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const HomeImgMobile = styled.div`
  width: 100%;
  height: 50vh;
  position: absolute;
  filter: brightness(100%);
  background-image: url(${(props) => props.src});
  background-size: cover;
  background-repeat: no-repeat;
  color: white;

  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const ServicesImg = styled.img`
  position: absolute;
  width: 100%;
  z-index: 1;
  background-size: contain;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ServicesImgMobile = styled.img`
  position: absolute;
  width: 100%;
  z-index: 1;
  background-size: contain;

  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const NewsImage = styled.img`
  width: 100%;
`;

export const DetailsImg = styled.img`
  width: 100%;
  height: 100%;
`;

export const NewsDiv = styled.div`
  margin-top: 15vh;
  text-align: center;
  margin-bottom: 5vh;
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
    align-content: center;
  }
`;

export const Col = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    margin-bottom: 10%;
  }
`;

export const NewsDesc = styled.p`
  color: white;
  font-size: 1vw;
  font-weight: 500;
  margin-top: 30%;
  margin-bottom: 2%;
  z-index: 2;
  padding-right: 10%;
  padding-left: 10%;

  @media screen and (max-width: 800px) {
    font-size: 5vw;
  }
`;

export const NewsDate = styled.p`
  color: white;
  font-size: 0.7vw;
  font-weight: 100;
  margin-left: 10%;
  margin-bottom: 20%;
  z-index: 2;
  @media screen and (max-width: 800px) {
    font-size: 4vw;
  }
`;
