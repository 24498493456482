import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../../reducers/userSlice.js";
import Aos from "aos";
import "aos/dist/aos.css";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as mdb from "mdb-ui-kit";
import "mdb-ui-kit/css/mdb.min.css";
import {
  RoadMapImgContainer,
  RoadMapBigLine,
  RoadMapBigCercle,
  RoadMapLeftContainer,
  RoadMapSmallCercle,
  RoadMapSmallLine,
  RoadMapText,
  RoadMapImgContainerMobile,
  MapMobileContainer,
} from "./OurServiceMapElements";

const OurServicesMap = (props) => {
  useState(() => {
    Aos.init({ duration: 2000 });
  }, []);
  const fontColor = props.fontColor;

  return (
    <>
      <RoadMapImgContainer>
        <RoadMapLeftContainer>
          <RoadMapBigCercle />
          <RoadMapBigLine />
          <RoadMapText style={{ color: fontColor }}>{props.text1}</RoadMapText>
        </RoadMapLeftContainer>
        <RoadMapSmallLine />
        <RoadMapLeftContainer>
          <RoadMapBigCercle />
          <RoadMapBigLine />
          <RoadMapText style={{ color: fontColor }}>{props.text2}</RoadMapText>
        </RoadMapLeftContainer>
        <RoadMapSmallLine />
        <RoadMapLeftContainer>
          <RoadMapBigCercle />
          <RoadMapBigLine />
          <RoadMapText style={{ color: fontColor }}>{props.text3}</RoadMapText>
        </RoadMapLeftContainer>
        <RoadMapSmallLine />
        <RoadMapLeftContainer>
          <RoadMapBigCercle />
          <RoadMapBigLine />
          <RoadMapText style={{ color: fontColor }}>{props.text4}</RoadMapText>
        </RoadMapLeftContainer>
        <RoadMapSmallLine />
        <RoadMapLeftContainer>
          <RoadMapBigCercle />
          <RoadMapBigLine />
          <RoadMapText style={{ color: fontColor }}>{props.text5}</RoadMapText>
        </RoadMapLeftContainer>
      </RoadMapImgContainer>

      <RoadMapImgContainerMobile>
        <MapMobileContainer>
          <RoadMapLeftContainer>
            <RoadMapBigCercle />
            <RoadMapBigLine />
            <RoadMapText style={{ color: fontColor }}>
              {props.text1}
            </RoadMapText>
          </RoadMapLeftContainer>
          <RoadMapSmallLine />
          <RoadMapLeftContainer>
            <RoadMapBigCercle />
            <RoadMapBigLine />
            <RoadMapText style={{ color: fontColor }}>
              {props.text2}
            </RoadMapText>
          </RoadMapLeftContainer>
          <RoadMapSmallLine />
          <RoadMapLeftContainer>
            <RoadMapBigCercle />
            <RoadMapBigLine />
            <RoadMapText style={{ color: fontColor }}>
              {props.text3}
            </RoadMapText>
          </RoadMapLeftContainer>
        </MapMobileContainer>
        <MapMobileContainer>
          <RoadMapLeftContainer>
            <RoadMapBigCercle />
            <RoadMapBigLine />
            <RoadMapText style={{ color: fontColor }}>
              {props.text4}
            </RoadMapText>
          </RoadMapLeftContainer>
          <RoadMapSmallLine />
          <RoadMapLeftContainer>
            <RoadMapBigCercle />
            <RoadMapBigLine />
            <RoadMapText style={{ color: fontColor }}>
              {props.text5}
            </RoadMapText>
          </RoadMapLeftContainer>
        </MapMobileContainer>
      </RoadMapImgContainerMobile>
    </>
  );
};

export default OurServicesMap;
