import styled from "styled-components";

export const ContactImage = styled.img`
  width: 50vw;
`;
export const ContactDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
`;
export const HelpDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  padding-left: 4%;
  padding-right: 7%;
  margin-top: -10%;

  @media screen and (max-width: 800px) {
    width: 100%;
  }
`;
export const HelpTitle = styled.p`
  color: white;
  font-size: 3vw;
  width: 13vw;
  margin-bottom: 10%;

  @media screen and (max-width: 800px) {
    width: 100%;
    font-size: 6vw;
  }
`;

export const HelpText = styled.p`
  color: white;
  font-size: 0.8vw;
  @media screen and (max-width: 800px) {
    font-size: 2vw;
  }
`;

export const ContactForm = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  padding-left: 5%;
  padding-right: 10%;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ContactFormMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
  padding-left: 5%;
  padding-right: 5%;
  margin-bottom: 10%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const ContactField = styled.input`
  width: 100%;
  height: 50px;
  border: 0;
  border-radius: 7px;
  margin-bottom: 3vw;
`;

export const ContactTextArea = styled.textarea`
  width: 100%;
  border-radius: 7px;
`;

export const ContactLabel = styled.p`
  color: white;
`;

export const TowerLine = styled.img`
  width: 100%;
  bottom: 0;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const ColContact = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  width: 100%;
`;

export const RowContact = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: stretch;
  width: 100%;
`;

export const LineTower = styled.img`
  margin-top: "-5%";
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const LineTowerM = styled.img`
  margin-top: "-5%";
  width: 97.5vw;
  margin-bottom: 10%;
  @media screen and (min-width: 800px) {
    display: none;
    
  }
`;

export const LineTowerMobile = styled.img`
  margin-top: "-5%";
  width: 80vw;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const Mobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;
