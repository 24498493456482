import {
  HomeBackground,
  HomeImg,
  HomeTitle,
  HomeButton,
  DevelopmentImg,
  DevelopmentSubTitle,
  DevelopmentSideText,
  Icon,
  ProjectTitle,
  ProjectDescription,
  Row,
  Col,
  HomeImgMobile,
  HomeBackgroundMobile,
  HomeTitleMobile,
  RowMobile,
  ColMobile,
} from "./WhyUsElement";
import WhyUsImage from "../../assets/images/WhyUsImage.png";
import Image1 from "../../assets/images/about-why1.png";
import Image2 from "../../assets/images/about-why2.png";
import Quality from "../../assets/images/quality.png";
import Team from "../../assets/images/team.png";
import Image3 from "../../assets/images/about-why3.png";
import Experts from "../../assets/images/experts.png";
import Image4 from "../../assets/images/about-why4.png";
import Technical from "../../assets/images/technical-support.png";
import Image5 from "../../assets/images/about-why5.png";
import Investor from "../../assets/images/investor.png";
import Image6 from "../../assets/images/about-why6.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import WhyUsImageMobile from "../../assets/images/WhyUsMobile.png";

const WhyUsPage = () => {
  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        <HomeImg src={WhyUsImage}></HomeImg>
        <HomeTitle>WHY US?</HomeTitle>
        <div style={{ textAlign: "center" }}>
          <HomeButton>Explore</HomeButton>
        </div>
      </HomeBackground>

      <HomeBackgroundMobile>
        <HomeImgMobile src={WhyUsImageMobile}></HomeImgMobile>
        <HomeTitleMobile>WHY US?</HomeTitleMobile>
        <div style={{ textAlign: "center" }}>
          <HomeButton>Explore</HomeButton>
        </div>
      </HomeBackgroundMobile>

      <Row>
        <DevelopmentImg src={Image1} />
        <Col>
          <div>
            <Icon src={Quality} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>High Quality Projects</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We select only the best projects in the market to work with. We
              are selective in what we sell and choose to only work with a
              select number of high-quality developers.
            </ProjectDescription>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Icon src={Team} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Comprehensive Service</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We provide full-service consultancy, allowing us to support with a
              full cycle solution for all types of RE assets.
            </ProjectDescription>
          </div>
        </Col>
        <DevelopmentImg src={Image2} />
      </Row>
      <Row>
        <DevelopmentImg src={Image3} />
        <Col>
          <div>
            <Icon src={Experts} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Expert Knowledge</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              All our agents are hand-picked and have been trained in real
              estate and undergo regular training sessions and market knowledge
              workshops.
            </ProjectDescription>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Icon src={Technical} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Technologically Advanced</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We are a tech enabled agency adopting the latest technological
              trends backed-up by a fully-fledged development team.
            </ProjectDescription>
          </div>
        </Col>
        <DevelopmentImg src={Image4} />
      </Row>
      <Row>
        <DevelopmentImg src={Image5} />
        <Col>
          <div>
            <Icon src={Investor} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Investment Expertise</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              Our team is highly experienced in dealing with retail and
              institutional clients. We work with several high-end institutional
              clients sourcing exclusive assets and projects.
            </ProjectDescription>
          </div>
        </Col>
      </Row>
      <Row>
        <Col>
          <div>
            <Icon src={Investor} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Large Investor Network</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We are a tech enabled agency adopting the latest technological
              trends backed-up by a fully-fledged development team.
            </ProjectDescription>
          </div>
        </Col>
        <DevelopmentImg src={Image6} />
      </Row>

      {/* Mobile */}

      <RowMobile>
        <DevelopmentImg src={Image1} />
        <ColMobile>
          <div>
            <Icon src={Quality} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>High Quality Projects</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We select only the best projects in the market to work with. We
              are selective in what we sell and choose to only work with a
              select number of high-quality developers.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <RowMobile>
        <DevelopmentImg src={Image2} />
        <ColMobile>
          <div>
            <Icon src={Team} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Comprehensive Service</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We provide full-service consultancy, allowing us to support with a
              full cycle solution for all types of RE assets.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <RowMobile>
        <DevelopmentImg src={Image3} />
        <ColMobile>
          <div>
            <Icon src={Experts} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Expert Knowledge</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              All our agents are hand-picked and have been trained in real
              estate and undergo regular training sessions and market knowledge
              workshops.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <RowMobile>
        <DevelopmentImg src={Image4} />
        <ColMobile>
          <div>
            <Icon src={Technical} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Technologically Advanced</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We are a tech enabled agency adopting the latest technological
              trends backed-up by a fully-fledged development team.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <RowMobile>
        <DevelopmentImg src={Image5} />
        <ColMobile>
          <div>
            <Icon src={Investor} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Investment Expertise</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              Our team is highly experienced in dealing with retail and
              institutional clients. We work with several high-end institutional
              clients sourcing exclusive assets and projects.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <RowMobile>
        <DevelopmentImg src={Image6} />
        <ColMobile>
          <div>
            <Icon src={Investor} />
          </div>
          <div style={{ marginTop: "5%" }}>
            <ProjectTitle>Large Investor Network</ProjectTitle>
          </div>
          <div style={{ marginTop: "10%" }}>
            <ProjectDescription>
              We are a tech enabled agency adopting the latest technological
              trends backed-up by a fully-fledged development team.
            </ProjectDescription>
          </div>
        </ColMobile>
      </RowMobile>
      <Footer />
    </>
  );
};

export default WhyUsPage;
