import { useState } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import {
  Div,
  DivBody,
  DivHeader,
  DivText,
  ImageProp,
} from "./PropertiesElement";
import Image from "../../assets/images/properties.png";

const PropertiesCloseUp = (props) => {
  useState(() => {
    Aos.init({ duration: 500 });
  }, []);

  const title = props.titleState;
  const text = props.textState;
  const source = props.sourceState;

  return (
    <Div style={{ zIndex: "2" }}>
      <ImageProp
        data-aos="zoom-in"
        style={{ opacity: "1", zIndex: "4" }}
        src={source}
      />
      <DivText data-aos="zoom-in">
        <DivHeader data-aos="zoom-in">{title}</DivHeader>
        <DivBody data-aos="zoom-in">{text} </DivBody>
      </DivText>
    </Div>
  );
};

export default PropertiesCloseUp;
