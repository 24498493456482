import styled from "styled-components";

export const HomeBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  z-index: 1;

  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
    height: 100vh;
  }
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(60%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

export const HomeTitle = styled.h1`
  font-size: 2.9vw;
  text-align: center;
  color: white;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  padding: 5%;
  @media screen and (max-width: 800px) {
    font-size: 7vw;
  }
`;

export const HomeButton = styled.button`
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 5px;
  &:hover {
    background-color: white;
    color: black;
  }
  margin-bottom: 10%;

  @media screen and (max-width: 800px) {
    font-size: 5vw;
    padding-left: 4%;
    padding-right: 4%;
    padding-top: 2%;
    padding-bottom: 2%;
  }
`;

export const DevelopmentCenterText = styled.p`
  font-size: 100%;
  padding: 15%;
  font-weight: 100;
  @media screen and (max-width: 800px) {
    text-align: center;
    padding: 15%;
  }
`;

export const DevelopmentSideText = styled.p`
  font-size: 100%;
  font-weight: 100;
  @media screen and (max-width: 800px) {
    font-size: 2vw;    
  }
`;

export const DevelopmentImg = styled.img`
  width: 100%;
`;

export const DevelopmentSubTitle = styled.h2`
  border-bottom: 2px solid #96191e;
  @media screen and (max-width: 800px) {
    width: 70%;
    font-size: 4vw;
  }
`;

export const RowMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
`;

export const ColMobile = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
`;

export const DivDesk = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const DivMobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;
