import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const RoadMapBackground = styled.div`
  width: 100%;
  height: auto;
  background-color: white;
`;
export const RoadMapImgContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 5%;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const MapMobileContainer = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 5%;
`;
export const RoadMapImgContainerMobile = styled.div`
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 5%;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;
export const RoadMapBigCercle = styled.div`
  background-color: #96191e;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  z-index: 0;
  @media screen and (max-width: 1400px) {
    width: 40px;
    height: 40px;
  }
  @media screen and (max-width: 1000px) {
    width: 30px;
    height: 30px;
  }
`;
export const RoadMapSmallCercle = styled.div`
  background-color: #0f29d6;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`;

export const RoadMapLeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-end;
  align-items: center;
  align-content: stretch;
  margin-top: -20px;
  width: 2.8%;
`;
export const RoadMapBigLine = styled.div`
  border-left: 1px solid #fbca03;
  height: 40px;
`;
export const RoadMapSmallLine = styled.div`
  border-top: 1px solid #fbca03;
  width: 250px;
  opacity: 1;
  @media screen and (max-width: 1500px) {
    width: 150px;
  }
  @media screen and (max-width: 1000px) {
    width: 100px;
  }
`;
export const RoadMapContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: flex-start;
  align-content: stretch;
`;
export const RoadMapContentTitle = styled.h4`
  width: 100%;
`;

export const RoadMapContentDescription = styled.p`
  width: 100%;
  font-weight: 100;
  opacity: 0.6;
`;
export const RoadMapMainTitle = styled.h1`
  width: 100%;
  font-weight: 100;
  margin-bottom: 8%;
`;

export const RoadMapText = styled.p`
  font-weight: 500;
  font-size: 1vw;
  width: 100%;
  text-align: center;
  @media screen and (max-width: 800px) {
    margin-left: -350%;
    font-size: 1.5vw;
  }

  @media screen and (max-width: 650px) {
    margin-left: -350%;
    font-size: 2.2vw;
  }
`;
