import {
  FooterDiv,
  FooterDivs,
  FooterText,
  Icon,
  IconDiv,
  Logo,
  RowDiv,
  RowDivMobile,
} from "./FooterElement";
import logo from "../../assets/images/OsoulLogo.png";
import Insta from "../../assets/images/insta.png";
import Youtube from "../../assets/images/youtube.png";
import Tiktok from "../../assets/images/Tiktok.png";
import Facebook from "../../assets/images/facebook.png";
import LinkedIn from "../../assets/images/linkedIn.png";

const Footer = () => {
  return (
    <FooterDiv>
      <Logo src={logo} />
      <RowDiv>
        <FooterDivs>
          <p>Help Center</p>
          <p>Support</p>
        </FooterDivs>
        <FooterDivs>
          <p>SalesOffice</p>
          <p>
            Dubai, Business Bay,
            <br />
            Blue Bay Tower, 4th Floor
          </p>
        </FooterDivs>
        <FooterDivs>
          <p>Head Office</p>
          <p>
            Fly Dubai Building 2ndFloor <br />
            Ittihad Road,Dubai ,UAE
          </p>
        </FooterDivs>
        <FooterDivs>
          <p>Copyright © Osoul's property</p>
          <p>https://osoulproperties.com</p>
          <p>Info@OsoulProperties.com</p>
          <IconDiv>
            <Icon src={Insta} />
            <Icon src={Youtube} />
            <Icon src={Tiktok} />
            <Icon src={Facebook} />
            <Icon src={LinkedIn} />
          </IconDiv>
        </FooterDivs>
      </RowDiv>
      <RowDivMobile>
        <FooterDivs>
          <FooterText>Help Center</FooterText>
          <FooterText>Support</FooterText>
          <IconDiv>
            <Icon src={Insta} />
            <Icon src={Youtube} />
            <Icon src={Tiktok} />
            <Icon src={Facebook} />
            <Icon src={LinkedIn} />
          </IconDiv>
        </FooterDivs>
        <FooterDivs>
          <FooterText>SalesOffice</FooterText>
          <FooterText>
            Dubai, Business Bay,
            <br />
            Blue Bay Tower, 4th Floor
          </FooterText>
          <FooterText>Head Office</FooterText>
          <FooterText>
            Fly Dubai Building 2ndFloor <br />
            Ittihad Road,Dubai ,UAE
          </FooterText>
          <FooterText>Copyright © Osoul's property</FooterText>
          <FooterText>https://osoulproperties.com</FooterText>
          <FooterText>Info@OsoulProperties.com</FooterText>
        </FooterDivs>
      </RowDivMobile>
    </FooterDiv>
  );
};

export default Footer;
