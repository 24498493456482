import React from "react";
import { Carousel, Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/Navbar";
import {
  HomeBackground,
  DetailsImg,
  DetailsTitle,
  DetailsText,
  DetailsSubTitle,
  DetailsSubText,
  PropertiesFlexContainer,
  PropertiesFlexColumn,
  RowImages,
} from "./DetailsElements";
import detailsImg from "../../assets/details2.png";
import threeImg1 from "../../assets/BlueIsland.png";
import LandingCarousel from "../../components/LandingCarousel";
import Footer from "../../components/Footer";

const DetailsPage = () => {
  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        <Container style={{ width: "100%" }}>
          <LandingCarousel />
        </Container>
        <Container>
          <PropertiesFlexContainer>
            <PropertiesFlexColumn>
              <DetailsImg src={detailsImg} />
            </PropertiesFlexColumn>
            <PropertiesFlexColumn>
              <DetailsTitle>PROPERTY FEATURES & AMENITIES:</DetailsTitle>
              <DetailsText>
                Stretching along the glimmering canal that runs between the
                island and mainland, Creek Beach is conceived to afford you the
                ultimate family-friendly retreat. From 700 meters of pristine
                white sands to the infinity pool and spectacular sunset views,
                this is paradise reborn. Exceptional location, outstanding
                design and an eclectic lifestyle are skillfully combined at
                Grove to create an innovative residential project. Consisting of
                five elegant buildings, Grove offers the perfect homes away from
                the buzz of the city benefiting from the pedestrian-friendly
                environment that distinguishes Creek Beach.
              </DetailsText>
              <Row>
                <Col>
                  <DetailsSubTitle>Status</DetailsSubTitle>
                  <DetailsSubTitle>Area</DetailsSubTitle>
                  <DetailsSubTitle>Location</DetailsSubTitle>
                  <DetailsSubTitle>Type</DetailsSubTitle>
                  <DetailsSubTitle>Handover Date</DetailsSubTitle>
                  <DetailsSubTitle>Number of Units</DetailsSubTitle>
                  <DetailsSubTitle>Price Range</DetailsSubTitle>
                </Col>
                <Col>
                  <DetailsSubText>Off Plan</DetailsSubText>
                  <DetailsSubText>711 ft² - 2566 ft²</DetailsSubText>
                  <DetailsSubText>Creek Harbour</DetailsSubText>
                  <DetailsSubText>Apartments</DetailsSubText>
                  <DetailsSubText>Jun 2025</DetailsSubText>
                  <DetailsSubText>?</DetailsSubText>
                  <DetailsSubText>AED1mil - AED3mil</DetailsSubText>
                </Col>
              </Row>
            </PropertiesFlexColumn>
          </PropertiesFlexContainer>
        </Container>
        <Container style={{ paddingTop: "10%", paddingBottom: "10%" }}>
          <RowImages>
            <Col style={{ position: "relative" }}>
              <DetailsImg
                style={{
                  zIndex: "-2",
                  position: "absolute",
                  filter: "brightness(50%)",
                }}
                src={threeImg1}
              />
              <p
                style={{
                  color: "white",
                  fontSize: "150%",
                  fontWeight: "500",
                  marginLeft: "10%",
                  marginTop: "50%",
                  marginBottom: "2%",
                  zIndex: "2",
                }}
              >
                Bluewaters island
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "100%",
                  fontWeight: "100",
                  marginLeft: "10%",
                  marginBottom: "20%",
                  zIndex: "2",
                }}
              >
                Dubai
              </p>
            </Col>
            <Col style={{ position: "relative" }}>
              <DetailsImg
                style={{
                  zIndex: "-2",
                  position: "absolute",
                  filter: "brightness(50%)",
                }}
                src={threeImg1}
              />
              <p
                style={{
                  color: "white",
                  fontSize: "150%",
                  fontWeight: "500",
                  marginLeft: "10%",
                  marginTop: "50%",
                  marginBottom: "2%",
                  zIndex: "2",
                }}
              >
                dubai hills
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "100%",
                  fontWeight: "100",
                  marginLeft: "10%",
                  marginBottom: "20%",
                  zIndex: "2",
                }}
              >
                Dubai
              </p>
            </Col>
            <Col style={{ position: "relative" }}>
              <DetailsImg
                style={{
                  zIndex: "-2",
                  position: "absolute",
                  filter: "brightness(50%)",
                }}
                src={threeImg1}
              />
              <p
                style={{
                  color: "white",
                  fontSize: "150%",
                  fontWeight: "500",
                  marginLeft: "10%",
                  marginTop: "50%",
                  marginBottom: "2%",
                  zIndex: "2",
                }}
              >
                Palm Jumeirah
              </p>
              <p
                style={{
                  color: "white",
                  fontSize: "100%",
                  fontWeight: "100",
                  marginLeft: "10%",
                  marginBottom: "20%",
                  zIndex: "2",
                }}
              >
                Dubai
              </p>
            </Col>
          </RowImages>
        </Container>
        <Footer />
      </HomeBackground>
    </>
  );
};

export default DetailsPage;
