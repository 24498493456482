import styled from "styled-components";

export const HomeImg = styled.div`
  width: 98%;
  height: 100%;
  position: absolute;
  filter: brightness(60%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: -1;
  @media screen and (max-width: 992px) {
    background-attachment: scroll;
  }
`;
export const HomeContainerResponsive = styled.div`
  position: relative;
  @media screen and (max-width: 800px) {
    position: relative !important;
  }
`;
export const HomeBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const HomeTitle = styled.h1`
  font-size: 2.9vw;
  text-align: center;
  color: white;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  padding: 5%;
  padding-top: 20%;
  @media screen and (max-width: 800px) {
    font-size: 5vw;
    padding-bottom: 30%;
    padding-top: 30%;
  }
`;

export const FeatureRedButton = styled.button`
  border: 1px solid white;
  background-color: #96191e;
  color: white;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 5px;
  border: 0px;
  &: hover {
    background-color: white;
    color: #96191e;
  }
  margin-bottom: 10%;
`;
export const FeatureSelect = styled.select`
  background-color: white;
  color: #96191e;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 5px;
  border: 0px;
  cursor: pointer;
  &: focus {
    background-color: #96191e;
    color: white;
  }
  &: hover {
    background-color: #96191e;
    color: white;
  }

  margin-bottom: 10%;
  @media screen and (max-width: 992px) {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    padding-left: 2%;
    padding-right: 2%;
    padding-top: 0.5%;
    padding-bottom: 0.5%;
  }
  @media screen and (max-width: 800px) {
    font-size: 100%;
    width: 100%;
  }
`;

export const FeatureRedButtonsContainer = styled.div`
  padding-top: 10%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const FeatureRedButtonsContainerMobile = styled.div`
  display: none;
  @media screen and (max-width: 800px) {
    padding-top: 10%;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
  }
`;
export const FeaturedImg = styled.img`
  width: 100%;
  padding: 10%;
`;
export const FeaturedSubTitle = styled.h1`
  font-size: 100%;
  font-weight: 800;
  padding-left: 10%;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    white-space: normal;
    font-size: 150%;
    font-weight: 800;
  }
`;
export const ReadMoreSubTitle = styled.p`
  font-size: 0.8vw;
  white-space: nowrap;
  color: #96191e;
  width: 35%;
  text-decoration: underline;
  text-decoration-color: #96191e;
  cursor: pointer;
  padding-left: 10%;
  @media screen and (max-width: 800px) {
    font-size: 100%;
  }
`;
export const RowImagesBottom = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;
export const MobileModal = styled.div`
  @media screen and (max-width: 900px) {
    display: none;
  }
`;
