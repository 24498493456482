import styled from "styled-components";

export const HomeBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(100%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

export const ServicesImg = styled.img`
  width: 100%;
`;
export const OurServicesImg = styled.img`
  width: 100%;
`;

export const OurServicesTitle = styled.h1`
  color: white;
  font-weight: 800;
  font-size: 200%;
  @media screen and (max-width: 800px) {
    text-align: center;
  }
`;
export const OurServicesText = styled.p`
  color: white;
  font-weight: 100;
  font-size: 100%;
  width: 70%;
  @media screen and (max-width: 800px) {
    text-align: center;
    width: 100%;
  }
`;
export const ServicesContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  padding: 10%;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    padding: 10%;
  }
`;
export const ServicesTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
`;

export const OverviewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 10%;
`;

export const OverviewTitleImg = styled.img`
  width: 100%;
`;

export const NeedTitle = styled.h1`
  font-size: 350%;
  color: black;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  margin-top: 5%;
  font-weight: 100;
  filter: brightness(100%);
  @media screen and (max-width: 800px) {
    font-size: 200%;
  }
`;

export const DetailsTitle = styled.h1`
  font-weight: 800;
  font-size: 1.8vw;
  white-space: nowrap;
  border-bottom: 2px solid #96191e;
  width: 100%;
  padding-bottom: 3%;
  color: black;
  @media screen and (max-width: 800px) {
    font-size: 4.5vw;
    margin-left: -50%;
  }
`;

export const DetailsTitleBottom = styled.h1`
  font-weight: 800;
  font-size: 1.8vw;
  white-space: nowrap;
  border-bottom: 2px solid #96191e;
  width: 100%;
  padding-bottom: 3%;
  color: black;
  @media screen and (max-width: 800px) {
    font-size: 4.5vw;
    padding-top: 5%;
  }
`;
export const DetailsText = styled.p`
  @media screen and (max-width: 800px) {
    margin-left: -50%;
  }
`;

export const DetailsTextBottom = styled.p`
  @media screen and (max-width: 800px) {
  }
`;
export const FitoutTitle = styled.h1`
  margin-top: 20%;
  font-weight: 800;
  font-size: 300%;
  white-space: nowrap;
  width: 100%;
  color: black;
`;

export const FitoutText = styled.p`
  width: 190%;
  @media screen and (max-width: 800px) {
    padding: 3%;
    width: 100%;
  }
`;

export const WineBox = styled.div`
  background-color: #96191e;
  width: 350px;
  height: 350px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  margin-left: 5%;
  margin-top: 5%;
  @media screen and (max-width: 1402px) {
    width: 300px;
    height: 300px;
    margin-left: 5%;
    margin-top: 5%;
  }
  @media screen and (max-width: 1200px) {
    width: 250px;
    height: 250px;
    margin-left: 5%;
    margin-top: 5%;
  }
  @media screen and (max-width: 1200px) {
    width: 200px;
    height: 200px;
    margin-left: 5%;
    margin-top: 5%;
  }
  
  @media screen and (max-width: 800px) {
    width: 30vw;
    height: 30vw;
    margin-top: 5%;
  }

  @media screen and (max-width: 620px) {
    width: 35vw;
    height: 35vw;
    margin-top: 5%;
  }
  @media screen and (max-width: 570px) {
    width: 40vw;
    height: 40vw;
    margin-top: 5%;
  }
`;
export const WineBoxTitle = styled.div`
  color: white;
  text-align: center;
  font-size: 150%;
  @media screen and (max-width: 800px) {
    font-size: 100%;
  }
`;
export const RowFitout = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: space-evenly;
    align-items: center;
    align-content: stretch;
  }
`;
export const WineBoxIcon = styled.img`
  width: 45%;
  height: 45%;
`;

export const DetailsImg = styled.img`
  width: 100%;
  height: 100%;

  @media screen and (min-width: 801px) {
    padding-left: 5%;
    padding-right: 5%;
  }
  @media screen and (max-width: 800px) {
    padding-top: 20%;
    padding-bottom: 10%;
  }
`;

export const NewsLine = styled.div`
  border-left: 1px solid #96191e;
  height: 250px;
  opacity: 1;
  z-index: 2;
  margin-left: 100%;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const RowServicesFlex = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    align-content: center;
  }
`;
