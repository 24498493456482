import React, { useEffect, useState } from "react";
import {
  FaBars,
  FaDiscord,
  FaInstagram,
  FaTwitter,
  FaLinkedin,
} from "react-icons/fa";
import Hamburger from "hamburger-react";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../../reducers/userSlice.js";
import { Col, Row } from "react-bootstrap";
import {
  Nav,
  NavWhite,
  NavLogo,
  Navs,
  SubNavs,
  NavsMobile,
  NavPageMobile,
} from "./NavbarElements";
import osoulLogo from "../../assets/osoulLogo.png";
import osoulLogoBlack from "../../assets/osoulLogoBlack.png";

const Navbar = (props) => {
  const isWhite = props.isWhite;
  const [isOpen, setOpen] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const navigate = useNavigate();
  const goTo = (to) => {
    navigate(to);
  };

  return (
    <>
      {!isWhite && (
        <Nav style={{ position: "absolute"}}>
          <NavLogo
            onClick={() => {
              goTo("/");
            }}
            style={{ backgroundColor: "transparent" }}
            src={osoulLogo}
          />
          <Navs>
            <SubNavs
              onClick={() => {
                goTo("/about");
              }}
            >
              About
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/development");
              }}
            >
              Development
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/featured");
              }}
            >
              Featured Projects
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/interiordesign");
              }}
            >
              Interior Design
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/news");
              }}
            >
              News
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/services");
              }}
            >
              Services
            </SubNavs>
            <SubNavs
              onClick={() => {
                goTo("/contact");
              }}
            >
              Contact
            </SubNavs>
          </Navs>
          <NavsMobile
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            {showNav && (
              <Hamburger color="red" toggled={isOpen} toggle={setOpen} />
            )}
            {!showNav && (
              <Hamburger color="white" toggled={isOpen} toggle={setOpen} />
            )}
          </NavsMobile>
        </Nav>
      )}
      {isWhite && (
        <NavWhite>
          <NavLogo
            onClick={() => {
              goTo("/");
            }}
            src={osoulLogoBlack}
          />
          <Navs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/about");
              }}
            >
              About
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/development");
              }}
            >
              Development
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/featured");
              }}
            >
              Featured Projects
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/interiordesign");
              }}
            >
              Interior Design
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/news");
              }}
            >
              News
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/services");
              }}
            >
              Services
            </SubNavs>
            <SubNavs
              style={{ color: "black" }}
              onClick={() => {
                goTo("/contact");
              }}
            >
              Contact
            </SubNavs>
          </Navs>
          <NavsMobile
            onClick={() => {
              setShowNav(!showNav);
            }}
          >
            <Hamburger toggled={isOpen} toggle={setOpen} />
          </NavsMobile>
        </NavWhite>
      )}
      {showNav && (
        <NavPageMobile>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/about");
            }}
          >
            About
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/development");
            }}
          >
            Development
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/featured");
            }}
          >
            Featured Projects
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/interiordesign");
            }}
          >
            Interior Design
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/news");
            }}
          >
            News
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/services");
            }}
          >
            Services
          </SubNavs>
          <SubNavs
            style={{ color: "black" }}
            onClick={() => {
              goTo("/contact");
            }}
          >
            Contact
          </SubNavs>
        </NavPageMobile>
      )}
    </>
  );
};

export default Navbar;
