import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { login, logout } from "../../reducers/userSlice.js";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as mdb from "mdb-ui-kit";
import "mdb-ui-kit/css/mdb.min.css";
import {
  CarouselBoxBackground,
  CarouselBoxBackgroundActive,
  CarouselBoxImg,
  CarouselBoxDetails,
  CarouselBoxTitle,
  CarouselBoxDescription,
} from "./LandingCarouselElements";
import reitrackLogo from "../../assets/BlueIsland.png";
const CarouselBox = (props) => {
  const active = props.active;

  return (
    <>
      {active && (
        <CarouselBoxBackgroundActive>
          <CarouselBoxImg src={reitrackLogo} />
        </CarouselBoxBackgroundActive>
      )}

      {!active && (
        <CarouselBoxBackground>
          <CarouselBoxImg src={reitrackLogo} />
        </CarouselBoxBackground>
      )}
    </>
  );
};

export default CarouselBox;
