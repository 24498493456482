import React from "react";
import { useParams } from "react-router-dom";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import Navbar from "../../components/Navbar";
import {
  StatusContainer,
  StatusNews,
  NewsTitle,
  NewsDate,
  NewsContent,
  NewsImgContainer,
  NewsButton,
  RowNewsInfo,
} from "./NewsInfoElements";
import { useNavigate } from "react-router-dom";
import newInfo from "../../assets/newsInfo.png";
import newImg from "../../assets/newsImg.png";
import Footer from "../../components/Footer";

const NewsInfo = (props) => {
  const { page } = useParams();
  const navigate = useNavigate();

  const goTo = (to) => {
    navigate(to);
  };

  return (
    <>
      <Navbar isWhite={true} />
      <StatusContainer>
        <StatusNews src={newInfo}></StatusNews>
      </StatusContainer>
      <Container
        style={{
          backgroundColor: "white",
          borderRadius: "5px",
          width: "90%",
          marginTop: "-15%",
          boxShadow:
            "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0px rgba(0, 0, 0, 0.19)",
        }}
      >
        <RowNewsInfo>
          <Col>
            <Row>
              <NewsTitle>
                Business Excellence Awards 2019-2020: Tracing a journey of
                excellence
              </NewsTitle>
            </Row>
            <Row>
              <NewsDate>November 30-2020</NewsDate>
            </Row>
            <Row>
              <NewsContent>
                Osoul Properties & OTS are one of the fastest growing facility
                management and a leading worldwide supplier of building
                material, from floor & wall tiles to sanitary ware, leveraging
                on the beneficial synergistic partnerships within the group, we
                are dedicated to offering fully integrated real estate solutions
                Expert Properties is very proud to offer you 2 Bedroom apartment
                in Aces Chateau, JVC About Aces Chateau, JVC Aces Chateau is a
                freehold residential project of Rijas Aces in Jumeirah Village
                District. Jumeriah Village Circle (JVC) is an elegant
                residential community of Nakheel in the heart of Dubai. Aces
                Chateau provides a peaceful and luxury living in a
                Renaissance-inspired apartment complex. Osoul Properties & OTS
                are one of the fastest growing facility management and a leading
                worldwide supplier of building material, from floor & wall tiles
                to sanitary ware, leveraging on the beneficial synergistic
                partnerships within the group, we are dedicated to offering
                fully integrated real estate solutions Expert Properties is very
                proud to offer you 2 Bedroom apartment in Aces Chateau, JVC
                About Aces Chateau, JVC Aces Chateau is a freehold residential
                project of Rijas Aces in Jumeirah Village District. Jumeriah
                Village Circle (JVC) is an elegant residential community of
                Nakheel in the heart of Dubai. Aces Chateau provides a peaceful
                and luxury living in a Renaissance-inspired apartment complex.
              </NewsContent>
            </Row>
          </Col>
          <Col style={{ textAlign: "center" }}>
            <NewsImgContainer src={newImg} />
          </Col>
        </RowNewsInfo>
      </Container>
      <Container>
        <NewsButton
          onClick={() => {
            goTo("/news");
          }}
        >
          Back
        </NewsButton>
      </Container>
      <Footer />
    </>
  );
};

export default NewsInfo;
