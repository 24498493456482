import styled from "styled-components";

export const Div = styled.div`
  margin-top: 25%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  position: absolute;
  width: 1000px;
  margin-left: -50%;
  @media screen and (max-width: 1014px) {
    width: 700px;
    margin-left: -20%;
  }
`;

export const ImageProp = styled.img`
  width: 50%;
`;

export const DivText = styled.div`
  z-index: 3;
  opacity: 1;
  background-color: white;
  width: 50%;
  margin-left: -0.99%;
  border-radius: 5px;
  padding-left: 2%;
  padding-right: 2%;
  padding-top: 10%;
  padding-bottom: 10%;
  @media screen and (max-width: 1014px) {
    padding-top: 10%;
    padding-bottom: 10%;
    font-size: 70%;
  }
`;

export const DivHeader = styled.div`
  font-size: 100%;
  z-index: 3;
  opacity: 1;
`;

export const DivBody = styled.div`
  font-size: 100%;
  z-index: 3;
  opacity: 1;
`;
