import Image from "../../assets/images/ContactImage.png";
import Line from "../../assets/images/ContactLine.png";
import LineMobile from "../../assets/images/LineMobile.png";
import Towers from "../../assets/images/ContactTowers.png";
import LineM from "../../assets/images/LineM.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";
import {
  ColContact,
  ContactDiv,
  ContactField,
  ContactForm,
  ContactFormMobile,
  ContactImage,
  ContactLabel,
  ContactTextArea,
  HelpDiv,
  HelpText,
  HelpTitle,
  LineTower,
  LineTowerM,
  LineTowerMobile,
  Mobile,
  RowContact,
  TowerLine,
} from "./ContactElement";

const Contact = () => {
  return (
    <div style={{ backgroundColor: "#182029" }}>
      <Navbar isWhite={true} />

      <ContactDiv>
        <ContactImage src={Image} />
        <ColContact>
          <RowContact>
            <HelpDiv>
              <HelpTitle>
                HOW <br /> CAN WE <br /> HELP?
              </HelpTitle>
              <HelpText>
                Want to get in touch with us?
                <br /> Drop us a message!
              </HelpText>
            </HelpDiv>
            <LineTower src={Line} alt="" />
            <ContactForm>
              <ContactLabel>Name</ContactLabel>
              <ContactField />
              <ContactLabel>Phone Number</ContactLabel>
              <ContactField />
              <ContactLabel>Email</ContactLabel>
              <ContactField />
              <ContactLabel>Message</ContactLabel>
              <ContactTextArea rows={5} placeholder="Write your message" />
            </ContactForm>
          </RowContact>
          <TowerLine src={Towers} />
        </ColContact>
      </ContactDiv>
      <Mobile
        style={{ textAlign: "center", marginTop: "5%", marginBottom: "5%" }}
      >
        <LineTowerMobile src={LineMobile} alt="" />
      </Mobile>
      <Mobile>
        <ContactFormMobile>
          <ContactLabel>Name</ContactLabel>
          <ContactField />
          <ContactLabel>Phone Number</ContactLabel>
          <ContactField />
          <ContactLabel>Email</ContactLabel>
          <ContactField />
          <ContactLabel>Message</ContactLabel>
          <ContactTextArea rows={5} placeholder="Write your message" />
        </ContactFormMobile>
        <LineTowerM src={LineM} />
      </Mobile>
      <Footer />
    </div>
  );
};

export default Contact;
