import React from "react";
import Navbar from "../../components/Navbar";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  HomeBackground,
  HomeImg,
  HomeTitle,
  HomeButton,
  DevelopmentCenterText,
  DevelopmentImg,
  DevelopmentSubTitle,
  DevelopmentSideText,
  DivDesk,
  DivMobile,
  ColMobile,
} from "./DevelopmentElements";
import developmentHomeImg from "../../assets/developmentHome.png";
import developmentImg1 from "../../assets/images/DevelopmentImg1.png";
import developmentImg2 from "../../assets/developmentImg2.png";
import developmentImg3 from "../../assets/developmentImg3.png";

import Footer from "../../components/Footer";

const DevelopmentPage = () => {
  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        <HomeImg src={developmentHomeImg}></HomeImg>
        <HomeTitle>DEVELOPMENT</HomeTitle>
        <HomeButton>Explore</HomeButton>
      </HomeBackground>
      <DevelopmentCenterText>
        Osoul Development is at the heart of our organization. Osoul offers a
        unique set of integrated real estate services to its clients while
        applying intuitive concepts that break away from familiarity and deliver
        exclusive projects that leave their mark in the ever- growing UAE
        property market. Osoul uses a formula that combines functional business
        acumen with emotional intuition to achieve the best possible results for
        its clients and make a difference when compared against the traditional
        real estate service providers.
      </DevelopmentCenterText>
      <DivDesk>
        <Container>
          <Row>
            <Col style={{ paddingRight: "5%" }}>
              <DevelopmentImg src={developmentImg1} />
            </Col>
            <Col>
              <DevelopmentSubTitle>
                Jumeirah luxury Villas & Townhouses Jumeirah Golf Estates Dubai.
              </DevelopmentSubTitle>
              <DevelopmentSideText>
                Osoul offers a complete development management range of
                services, starting from the financial due diligence of an
                investment where all parameters are assessed before the client
                takes a decision to proceed. Once this has been achieved, we
                move on to the design of the project coupling our extensive
                experience and expertise with renowned architectural design and
                engineering offices, depending on the project complexity and
                type.
              </DevelopmentSideText>
            </Col>
          </Row>
        </Container>
        <Container style={{ paddingTop: "10%" }}>
          <Row>
            <Col style={{ paddingRight: "5%" }}>
              <DevelopmentSubTitle>
                Jumeirah luxury Villas & Townhouses Jumeirah Golf Estates Dubai.
              </DevelopmentSubTitle>
              <DevelopmentSideText>
                Osoul offers a complete development management range of
                services, starting from the financial due diligence of an
                investment where all parameters are assessed before the client
                takes a decision to proceed. Once this has been achieved, we
                move on to the design of the project coupling our extensive
                experience and expertise with renowned architectural design and
                engineering offices, depending on the project complexity and
                type.
              </DevelopmentSideText>
            </Col>
            <Col>
              <DevelopmentImg src={developmentImg2} />
            </Col>
          </Row>
        </Container>
        <Container style={{ paddingTop: "10%", paddingBottom: "10%" }}>
          <Row>
            <Col style={{ paddingRight: "5%" }}>
              <DevelopmentImg src={developmentImg3} />
            </Col>

            <Col>
              <DevelopmentSubTitle>
                Jumeirah luxury Villas & Townhouses Jumeirah Golf Estates Dubai.
              </DevelopmentSubTitle>
              <DevelopmentSideText>
                Osoul offers a complete development management range of
                services, starting from the financial due diligence of an
                investment where all parameters are assessed before the client
                takes a decision to proceed. Once this has been achieved, we
                move on to the design of the project coupling our extensive
                experience and expertise with renowned architectural design and
                engineering offices, depending on the project complexity and
                type.
              </DevelopmentSideText>
            </Col>
          </Row>
        </Container>
      </DivDesk>
      <DivMobile>
        <Container>
          <ColMobile>
            <DevelopmentImg src={developmentImg1} />
            <br />
            <DevelopmentSubTitle>
              Jumeirah luxury Villas & Townhouses Jumeirah Golf Estates Dubai.
            </DevelopmentSubTitle>
            <br />
            <DevelopmentSideText>
              Osoul offers a complete development management range of services,
              starting from the financial due diligence of an investment where
              all parameters are assessed before the client takes a decision to
              proceed. Once this has been achieved, we move on to the design of
              the project coupling our extensive experience and expertise with
              renowned architectural design and engineering offices, depending
              on the project complexity and type.
            </DevelopmentSideText>

            <br />
            <br />

            <DevelopmentImg src={developmentImg2} />
            <br />
            <DevelopmentSubTitle>
              Motor City Expansion Phase 2
            </DevelopmentSubTitle>
            <br />
            <DevelopmentSideText>
              ASI was awarded the joinery contract for the Green Community
              Expansion project. This is another large scale project developed
              for Union Properties. Phases 2 and 3 of this project will feature
              227 units including 210 townhouses, and 16 duplex apartments.
              <br />
              Client: Union Properties Consultant: National Properties Location:
              Green Community, Dubai Scope of work: Joinery work
            </DevelopmentSideText>

            <br />
            <br />

            <DevelopmentImg src={developmentImg3} />
            <br />
            <DevelopmentSubTitle>
              Motor City Expansion Phase 3
            </DevelopmentSubTitle>
            <br />
            <DevelopmentSideText>
              Having a solid grasp of the local planning requirements, we adjust
              the design to comply with local building codes, and then rigorous
              design reviews to ensure that all designs have been coordinated
              and contain complete information, we take project to tender. We
              also supervise the works on-site by applying thorough and hands-on
              project management techniques to safeguard the quality and timely
              completion of the project, always taking into consideration the
              project’s financial constraints. Osoul has the sophistication and
              flexibility to create value across multiple asset classes and
              marketplaces and is not limited by specific investment criteria
              but motivated by solid market fundamentals and value generation.
            </DevelopmentSideText>
            <br />
            <br />
          </ColMobile>
        </Container>
      </DivMobile>
      <Footer />
    </>
  );
};

export default DevelopmentPage;
