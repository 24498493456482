import styled from "styled-components";

export const FooterDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  background-color: #96191e;
  width: 100%;
  padding-top: 7%;

  @media screen and (max-width: 1650px) {
    padding-top: 5%;
    padding-bottom: 2%;
  }

  @media screen and (max-width: 800px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const Logo = styled.img`
  margin-left: 8%;
  margin-bottom: 13%;
  margin-right: 15%;
  @media screen and (max-width: 1650px) {
    margin-left: 5%;
    margin-right: 9%;
  }
  @media screen and (max-width: 1500px) {
    margin-left: 2%;
    margin-right: 4%;
  }

  @media screen and (max-width: 1350px) {
    margin-left: 2%;
    margin-right: 2%;
  }

  @media screen and (max-width: 1100px) {
    width: 100px;
    margin-bottom: 20%;
  }

  @media screen and (max-width: 800px) {
    width: 150px;
  }
`;

export const FooterDivs = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: stretch;
  color: white;
  margin-right: 10%;

  @media screen and (max-width: 1650px) {
    margin-right: 2%;
  }

  @media screen and (max-width: 1900px) {
    margin-right: 7%;
  }

  @media screen and (max-width: 1350px) {
    margin-right: 2%;
  }

  @media screen and (max-width: 1100px) {
    font-size: small;
  }

  @media screen and (max-width: 800px) {
    padding-left: 10%;
    margin-right: 10%;
  }

  @media screen and (max-width: 570px) {
    font-size: small;
  }
`;

export const RowDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  width: 100%;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const RowDivMobile = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: stretch;
  align-content: stretch;
  justify-content: space-between;
  width: 100%;

  @media screen and (min-width: 801px) {
    display: none;
  }
`;
export const IconDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  margin-left: 15%;
  margin-top: 3%;
`;

export const Icon = styled.img`
  margin-right: 15%;


  @media screen and (max-width: 1100px) {
    width: 30px;
    height: 20px;
  }

  @media screen and (max-width: 800px) {
    width: 3vw;
    height: 3vw;
  }
`;

export const FooterText = styled.p`
  font-size: 2vw;
`;