import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const LandingCarouselBackground = styled.nav`
  width: 100%;
  height: auto;
  background-color: white;
`;

export const LandingCarouselTitle = styled.h1`
  font-size: 400%;
  color: white;
  z-index: 2;
`;

export const CarouselBoxLeft = styled.div`
  width: 20%;
  bachground-color: red;
`;

export const CarouselBoxRight = styled.div`
  position: absolute;
  width: 100%;
  bachground-color: green;
`;

export const LandingCarouselDescription = styled.p`
  font-size: 100%;
  width: 30%;
  text-align: center;
  color: white;
`;

export const CarouselBoxBackground = styled.div`
  width: 100%;
  height: 320px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: white;
  transition-duration: 0.7s;
  filter: brightness(40%);
  z-index: -2;
  margin-top: 10%;
  @media screen and (max-width: 1000px) {
    height: 150px;
  }
`;
export const CarouselBoxBackgroundActive = styled.div`
  width: 100%;
  height: 400px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0px rgba(0, 0, 0, 0.19);
  border-radius: 10px;
  background-color: white;
  transition-duration: 0.7s;
  @media screen and (max-width: 1000px) {
    height: 200px;
  }
`;

export const CarouselBoxImg = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 10px 10px 10px 10px;
`;

export const CarouselBoxDetails = styled.p`
  background-color: #4d61f9;
  border-radius: 0px 0px 10px 10px;
  width: 95%;
  color: white;
  font-size: 80%;
  white-space: nowrap;
  padding-top: 5%;
  padding-bottom: 5%;
  text-align: center;
  margin-bottom: 3%;
`;
export const CarouselBoxTitle = styled.h6`
  color: #212e91;
  font-weight: 600;
`;
export const CarouselBoxDescription = styled.p`
  color: #212e91;
  font-weight: 100;
  font-size: 90%;
`;

export const CarouselDesktop = styled.div`
  @media screen and (max-width: 801px) {
    display: none;
  }
`;

export const CarouselMobile = styled.div`
  @media screen and (min-width: 800px) {
    display: none;
  }
`;
