import styled from "styled-components";
import { Link as LinkR } from "react-router-dom";
import { Link as LinkS } from "react-scroll";

export const Nav = styled.nav`
  width: 100%;
  height: 100px;
  position: relative;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 1009px) and (min-width: 700px) {
  }
`;

export const NavWhite = styled.nav`
  width: 100%;
  height: 100px;
  position: relative;
  background-color: white;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  z-index:2;
  @media screen and (max-width: 1009px) and (min-width: 700px) {
  }
`;

export const NavLogo = styled.img`
  height: auto;
  padding-left: 5%;
  cursor: pointer;
  z-index: 2;
  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding-left: 5%;
  }
  @media screen and (max-width: 1033px) and (min-width: 801px) {
    width: 15%;
    padding-left: 2%;
  }
  @media screen and (max-width: 800px) {
    width: 30%;
    padding-left: 5%;
  }
`;

export const Navs = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  padding-right: 15vw;
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const NavsMobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
  z-index: 5;
  padding-right: 5%;
`;

export const SubNavs = styled.p`
  padding: 3%;
  white-space: nowrap;
  font-size: 110%;
  color: white;
  cursor: pointer;
  &:hover {
    border-bottom: 1px solid red;
    color: red;
  }
  &:active {
    border-bottom: 1px solid red;
    color: red;
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1033px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
  z-index: 2;
`;

export const NavPageMobile = styled.div`
  background-color: white;
  position: absolute;
  z-index: 3;
  height: 700vh;
  width: 100%;
  padding: 15%;
`;
