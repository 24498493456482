import Navbar from "../../components/Navbar";
import {
  HomeBackground,
  HomeBackgroundMobile,
  HomeButton,
  HomeImg,
  HomeTitle,
  HomeTitleMobile,
  Image,
  SolutionDiv,
  SwiperDesk,
  SwiperMobile,
} from "./InteriorDesignElement";
import WhyUsImage from "../../assets/images/WhyUsImage.png";
import { Swiper, SwiperSlide } from "swiper/react";
import Image1 from "../../assets/images/al-ashram1.png";
import Image2 from "../../assets/images/al-ashram2.png";
import Image3 from "../../assets/images/galleria-villa-3.png";
import Image4 from "../../assets/images/habib-al-mulla-co-5.png";
import Image5 from "../../assets/images/hosari_0.png";
import Image6 from "../../assets/images/hosari_2.png";
import Image7 from "../../assets/images/hosari_3.png";
import Image8 from "../../assets/images/hussaini-residence-dubai-2016-23.png";
import Image9 from "../../assets/images/hussaini-residence-dubai-2016-120.png";
import Image10 from "../../assets/images/hussaini-residence-dubai-2016-52.png";
import Image11 from "../../assets/images/hussaini-residence-dubai-2016-10.png";
import Image12 from "../../assets/images/ialoft_1b.png";

// Import Swiper styles
import "swiper/css";
import "swiper/css/pagination";
import "./interior.css";
// import required modules
import { Pagination } from "swiper";
import Footer from "../../components/Footer";
import { HomeImgMobile } from "../WhyUs/WhyUsElement";

const InteriorDesign = () => {
  return (
    <div>
      <Navbar isWhite={true} />
      <HomeBackground>
        <HomeImg src={WhyUsImage}></HomeImg>
        <HomeTitle>INTERIOR DESIGN</HomeTitle>
        <div style={{ textAlign: "center" }}>
          <HomeButton>Explore</HomeButton>
        </div>
      </HomeBackground>
      <HomeBackgroundMobile>
        <HomeImgMobile src={WhyUsImage}></HomeImgMobile>
        <HomeTitleMobile>INTERIOR DESIGN</HomeTitleMobile>
        <div style={{ textAlign: "center" }}>
          <HomeButton>Explore</HomeButton>
        </div>
      </HomeBackgroundMobile>
      <SolutionDiv>
        <div>Interior Design Solutions</div>
        <br />
        <div style={{ textAlign: "justify", textAlignLast: "center" }}>
          Our prestigious interiors are sensory realizations of this shared
          imagination. We believe that good design should evoke a response, stir
          emotion, and inspire thought. That’s why all of our creations
          personify a lifestyle, a feeling and a purpose.
        </div>
        <br />
        <div style={{ textAlign: "justify", textAlignLast: "center" }}>
          Our custom-tailored interior design services span from residential,
          commercial, corporate, to hospitality clients across the UAE. Every
          space we create has its own distinctive character, carefully designed
          to express an individual narrative, and showcase the best of modern
          international designs.
        </div>
        <br />
        <div style={{ textAlign: "justify", textAlignLast: "center" }}>
          Having a wide range of strong suppliers in the industry, we also offer
          a fully comprehensive consulting & furniture procurement service. We
          have the skills and taste to select, specify and consult on furniture
          projects across varying market sectors.
        </div>
      </SolutionDiv>
      <div style={{ backgroundColor: "#181F28", paddingBottom: "5%" }}>
        <SwiperDesk>
          <Swiper
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              backgroundColor: "#181F28",
              paddingBottom: "5%",
            }}
            slidesPerView={2.5}
            navigation
            spaceBetween={20}
            pagination={true}
            modules={[Pagination]}
            scrollbar={{ draggable: true }}
          >
            <SwiperSlide>
              <Image src={Image1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image6} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image7} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image8} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image9} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image10} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image11} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image12} alt="" />
            </SwiperSlide>
          </Swiper>
        </SwiperDesk>

        {/* Mobile */}

        <SwiperMobile>
          <Swiper
            style={{
              cursor: "pointer",
              width: "100%",
              height: "100%",
              backgroundColor: "#181F28",
              paddingBottom: "15%",
            }}
            slidesPerView={1}
            navigation
            spaceBetween={20}
            pagination={true}
            modules={[Pagination]}
            scrollbar={{ draggable: true }}
          >
            <SwiperSlide>
              <Image src={Image1} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image2} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image3} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image4} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image5} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image6} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image7} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image8} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image9} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image10} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image11} alt="" />
            </SwiperSlide>
            <SwiperSlide>
              <Image src={Image12} alt="" />
            </SwiperSlide>
          </Swiper>
        </SwiperMobile>
      </div>
      <Footer />
    </div>
  );
};

export default InteriorDesign;
