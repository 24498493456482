import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { logout } from "../../reducers/userSlice.js";
import Aos from "aos";
import "aos/dist/aos.css";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as mdb from "mdb-ui-kit";
import "mdb-ui-kit/css/mdb.min.css";
import {
  HomeBackground,
  HomeImg,
  HomeTitle,
  OverviewTitle,
  OverviewText,
  OverviewButton,
  OverviewTitleContainer,
  OverviewTitleImg,
  OurServicesThreeContainer,
  OurServicesThreeContent,
  OurServiceButton,
  OurServicesText,
  FeaturedLandingTitle,
  FeaturedImg,
  NewsTitleLanding,
  NewsBoxLanding,
  NewsLandingContainer,
  NewsLine,
  DigitalContainer,
  DigitalTextContainer,
  DigitalTextTitle,
  DigitalTextContent,
  OverviewTitleServices,
  OverviewTitleImgServices,
  FeatureDesktop,
  FeatureMobile,
  NewsBoxDate,
  NewsBoxContent,
} from "./LandingElements.js";
import Navbar from "../../components/Navbar";
import landingHomeImg from "../../assets/landinHome.png";
import landingOverviewImg from "../../assets/OverviewLandingBackground.png";
import overviewLeft from "../../assets/overviewLeft.png";
import overviewRight from "../../assets/overviewRight.png";
import ourServicesCenter from "../../assets/ourServiceLanding.png";
import blueImg from "../../assets/BlueIsland.png";
import blueImg1 from "../../assets/BlueIsland2.png";
import newsBack from "../../assets/newsLanding.png";
import OurServicesMap from "../../components/OurServiceMap/index.js";
import digitalImg from "../../assets/DigitalImg.png";
import Footer from "../../components/Footer/index.js";

const Landing = () => {
  // const user = useSelector((state) => state.userSlice.user);
  // const dispatch = useDispatch();
  // const navigate = useNavigate();

  return (
    <>
      <Navbar isWhite={false} />
      <HomeBackground>
        <HomeImg src={landingHomeImg}></HomeImg>
        <HomeTitle>Unlock Opportunities</HomeTitle>
      </HomeBackground>
      <HomeBackground>
        <HomeImg
          style={{ backgrounAttachment: "normal" }}
          src={landingOverviewImg}
        ></HomeImg>
        <OverviewTitleContainer>
          <OverviewTitleImg src={overviewLeft} />
          <OverviewTitle>Overview</OverviewTitle>
          <OverviewTitleImg src={overviewRight} />
        </OverviewTitleContainer>
        <OverviewText>
          Osoul Properties was established in 2014. Osoul offers a wide range of
          real estate related services, stretching from development into
          property management, facility management, procurement of furnishings
          and fittings and interior design, thus providing its clients with a
          unique "all-in-one" approach to handling their assets. Osoul
          Properties approaches all of its projects with a critical eye, aiming
          to maximize efficiency and to create the most value possible for
          investors throughout each project’s life cycle.
        </OverviewText>
        <div style={{ textAlign: "center" }}>
          <OverviewButton>LEARN MORE</OverviewButton>
        </div>
      </HomeBackground>
      <HomeBackground style={{ backgroundColor: "white" }}>
        <OverviewTitleImgServices
          style={{ paddingTop: "5%" }}
          src={ourServicesCenter}
        />
        <OverviewTitleServices
          style={{
            color: "black",
            whiteSpace: "normal",
            paddingTop: "5%",
          }}
        >
          OUR SERVICES
        </OverviewTitleServices>
        <OurServicesThreeContainer>
          <OurServicesThreeContent>CONSTRUCTION</OurServicesThreeContent>
          <OurServicesThreeContent>FIT OUT</OurServicesThreeContent>
          <OurServicesThreeContent>DESIGN</OurServicesThreeContent>
        </OurServicesThreeContainer>
        <OurServicesText>
          Retail concept and tenant mix. Best development . To maximize the
          client profit
        </OurServicesText>
        <OurServicesMap
          fontColor="black"
          text1="FINDING A SPACE"
          text2="CONSTRUCTIVE SERVICES"
          text3="PROPERTY AND FACILITY MANAGEMENT"
          text4="BUILDING MATERIAL SUPPLIES"
          text5="PREOPENING PREPARING"
        />
        <div style={{ textAlign: "center", marginTop: "10%" }}>
          <OurServiceButton>READ MORE</OurServiceButton>
        </div>
      </HomeBackground>
      <HomeBackground style={{ backgroundColor: "white" }}>
        <Container style={{ paddingBottom: "10%" }}>
          <FeaturedLandingTitle>FEATURED PROJECTS</FeaturedLandingTitle>
          <FeatureDesktop>
            <Row style={{ paddingTop: "5%" }}>
              <Col>
                <Row style={{ position: "relative" }}>
                  <FeaturedImg src={blueImg} />
                  <p
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "10%",
                      color: "white",
                      fontSize: "200%",
                    }}
                  >
                    Bluewaters island
                  </p>
                </Row>
                <Row style={{ paddingTop: "4%" }}>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      Mohammed Bin Rashed City
                    </p>
                  </Col>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      downtown
                    </p>
                  </Col>
                </Row>
              </Col>
              <Col>
                <Row>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg1} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      dubai hills
                    </p>
                  </Col>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg1} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      Palm Jumeirah
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", position: "relative" }}>
                  <FeaturedImg src={blueImg1} />
                  <p
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "10%",
                      color: "white",
                      fontSize: "200%",
                    }}
                  >
                    Bluewaters island
                  </p>
                </Row>
              </Col>
            </Row>
          </FeatureDesktop>
          <FeatureMobile>
            <Row style={{ paddingTop: "5%" }}>
              <Col>
                <Row style={{ position: "relative" }}>
                  <FeaturedImg src={blueImg} />
                  <p
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "10%",
                      color: "white",
                      fontSize: "200%",
                    }}
                  >
                    Bluewaters island
                  </p>
                </Row>
                <Row style={{ paddingTop: "4%" }}>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      Mohammed Bin Rashed City
                    </p>
                  </Col>
                  <Col style={{ position: "relative" }}>
                    <FeaturedImg src={blueImg} />
                    <p
                      style={{
                        position: "absolute",
                        top: "40%",
                        left: "10%",
                        color: "white",
                        fontSize: "100%",
                      }}
                    >
                      downtown
                    </p>
                  </Col>
                </Row>
                <Row style={{ paddingTop: "4%", position: "relative" }}>
                  <FeaturedImg src={blueImg1} />
                  <p
                    style={{
                      position: "absolute",
                      top: "60%",
                      left: "10%",
                      color: "white",
                      fontSize: "200%",
                    }}
                  >
                    Bluewaters island
                  </p>
                </Row>
              </Col>
            </Row>
          </FeatureMobile>
        </Container>
      </HomeBackground>
      <HomeBackground>
        <HomeImg src={newsBack}></HomeImg>
        <NewsLandingContainer>
          <Col>
            <NewsTitleLanding>News</NewsTitleLanding>
          </Col>
          <Col>
            <NewsBoxLanding>
              <Col>
                <NewsBoxDate>17-May-2019</NewsBoxDate>
                <NewsBoxContent>
                  Osoul Properties unveil Jumeirah Luxury Living Villas range
                </NewsBoxContent>
              </Col>
              <NewsLine style={{ marginLeft: "10%" }} />
              <Col>
                <NewsBoxDate>November 30-2020</NewsBoxDate>
                <NewsBoxContent>
                  Business Excellence Awards 2019-2020: Tracing a journey of
                  excellence
                </NewsBoxContent>
              </Col>
            </NewsBoxLanding>
          </Col>
        </NewsLandingContainer>
      </HomeBackground>
      <HomeBackground>
        <DigitalContainer>
          <DigitalTextContainer>
            <DigitalTextTitle>DIGITAL EXPERIENCE</DigitalTextTitle>
            <DigitalTextContent>
              We select only the best projects in the market to work with. We
              are selective in what we sell and choose to only work with a
              select number of high-quality developers.
            </DigitalTextContent>
          </DigitalTextContainer>
          <FeaturedImg src={digitalImg} />
        </DigitalContainer>
      </HomeBackground>
      <Footer />
    </>
  );
};

export default Landing;
