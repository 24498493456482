import { useNavigate } from "react-router-dom";
import {
  Col,
  DetailsImg,
  HomeBackground,
  HomeImg,
  HomeImgMobile,
  NewsDate,
  NewsDesc,
  NewsDiv,
  Row,
  ServicesImg,
  ServicesImgMobile,
} from "./NewsElement";
import towersImg from "../../assets/images/TowersLine.png";
import towersImgMobile from "../../assets/images/TowersLine2.png";
import statusImg from "../../assets/images/NewsStatus.png";
import statusImgMobile from "../../assets/images/NewsMobile.png";
import Image1 from "../../assets/images/News1.png";
import Image2 from "../../assets/images/News2.png";
import Image3 from "../../assets/images/News3.png";
import Footer from "../../components/Footer";
import { Container } from "react-bootstrap";
import Navbar from "../../components/Navbar";
const News = () => {
  const navigate = useNavigate();
  const goToInfo = () => {
    navigate("/newsInfo/1");
  };

  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        <HomeImg src={statusImg}></HomeImg>
        <HomeImgMobile src={statusImgMobile}></HomeImgMobile>
        <ServicesImg
          style={{ paddingTop: "7%", paddingBottom: "7%" }}
          src={towersImg}
        />
        <ServicesImgMobile src={towersImgMobile} />
      </HomeBackground>
      <NewsDiv>
        <h1>NEWS</h1>
      </NewsDiv>
      <div style={{ marginTop: "5%", marginBottom: "15%" }}>
        <Row>
          <Col
            onClick={() => {
              goToInfo();
            }}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <DetailsImg
              style={{
                zIndex: "-2",
                position: "absolute",
              }}
              src={Image1}
            />
            <NewsDesc>
              Business Excellence Awards 2019-2020: Tracing a journey of
              excellence
            </NewsDesc>
            <NewsDate>November 30-2020</NewsDate>
          </Col>

          <Col
            onClick={() => {
              goToInfo();
            }}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <DetailsImg
              style={{
                zIndex: "-2",
                position: "absolute",
              }}
              src={Image2}
            />
            <NewsDesc>
              Osoul Properties unveil Jumeirah Luxury Living Villas range
            </NewsDesc>
            <NewsDate>November 30-2020</NewsDate>
          </Col>

          <Col
            onClick={() => {
              goToInfo();
            }}
            style={{ cursor: "pointer", position: "relative" }}
          >
            <DetailsImg
              style={{
                zIndex: "-2",
                position: "absolute",
              }}
              src={Image3}
            />
            <NewsDesc>
              best Mortgage Development in Gulf Real Estate Awards 2017
            </NewsDesc>
            <NewsDate>17-Jan-2018</NewsDate>
          </Col>
        </Row>
      </div>
      <Footer />
    </>
  );
};

export default News;
