import React, { useState, useEffect, Suspense } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
// import { Spinner } from 'react-bootstrap';
import Aos from "aos";
import "aos/dist/aos.css";
import "./App.css";
import Landing from "./pages/Landing/index.js";
import DevelopmentPage from "./pages/Development";
import AboutUs from "./pages/AboutUs";
import FeaturedPage from "./pages/Featured";
import DetailsPage from "./pages/Details";
import ServicesPage from "./pages/Services";
import WhyUsPage from "./pages/WhyUs";
import InteriorDesign from "./pages/InteriorDesign";
import News from "./pages/News";
import NewsInfo from "./pages/NewsInfo";
import PropertiesCloseUp from "./pages/PropertiesCloseUp";
import Contact from "./pages/Contact";
import LandingCarousel from "./components/LandingCarousel";

function App() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<Landing />} />
        <Route exact path="/development" element={<DevelopmentPage />} />
        <Route exact path="/about" element={<AboutUs />} />
        <Route exact path="/featured" element={<FeaturedPage />} />
        <Route exact path="/details" element={<DetailsPage />} />
        <Route exact path="/services" element={<ServicesPage />} />
        <Route exact path="/whyus" element={<WhyUsPage />} />
        <Route exact path="/interiordesign" element={<InteriorDesign />} />
        <Route exact path="/news" element={<News />} />
        <Route exact path="/newsInfo/:page" element={<NewsInfo />} />
        <Route
          exact
          path="/propertiescloseup"
          element={<PropertiesCloseUp />}
        />
        <Route exact path="/contact" element={<Contact />} />
      </Routes>
    </Router>
  );
}

export default App;
