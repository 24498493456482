import styled from "styled-components";

export const HomeBackground = styled.div`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(60%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

export const HomeTitle = styled.h1`
  font-size: 2.9vw;
  text-align: center;
  color: white;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  padding: 5%;
  padding-top: 15%;
`;

export const HomeButton = styled.button`
  border: 1px solid white;
  background-color: transparent;
  color: white;
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 0.5%;
  padding-bottom: 0.5%;
  border-radius: 5px;
  &:hover {
    background-color: white;
    color: black;
  }
  margin-bottom: 10%;

  @media screen and (max-width: 800px) {
    padding-left: 10%;
    padding-right: 10%;
    padding-top: 1%;
    padding-bottom: 1%;
    border-radius: 5px;
  }
`;

export const SolutionDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
  height: 100vh;
  background-color: #181f28;
  color: white;
  font-size: 1.5vw;
  padding: 15%;

  @media screen and (max-width: 800px) {
    padding: 5%;
    font-size: 2.5vw;
    height: 100vw;
  }
`;

export const Image = styled.img`
  width: 100%;
`;

export const HomeBackgroundMobile = styled.div`
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;

  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;

  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const HomeTitleMobile = styled.h1`
  font-size: 10vw;
  text-align: center;
  color: white;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  padding: 5%;
  /* padding-top: 50%; */
`;

export const SwiperDesk = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const SwiperMobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;