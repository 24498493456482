import React from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import { Col, Row, Container } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import * as mdb from "mdb-ui-kit";
import "mdb-ui-kit/css/mdb.min.css";
import {
  HomeBackground,
  HomeImg,
  ServicesImg,
  OurServicesTitle,
  OurServicesText,
  OurServicesImg,
  ServicesContainer,
  ServicesTextContainer,
  OverviewTitleContainer,
  OverviewTitleImg,
  NeedTitle,
  DetailsTitle,
  DetailsText,
  WineBox,
  WineBoxTitle,
  WineBoxIcon,
  DetailsImg,
  NewsLine,
  FitoutText,
  FitoutTitle,
  RowServicesFlex,
  DetailsTextBottom,
  DetailsTitleBottom,
  RowFitout,
} from "./ServicesElements";
import FitoutMap from "../../components/FitoutMap";
import statusImg from "../../assets/statusServices.png";
import lastPic from "../../assets/lastPic.png";
import towersImg from "../../assets/ourServiceLanding.png";
import servicesTowerImg from "../../assets/servicesTower.png";
import serviceDeveloper from "../../assets/serviceDeveloper.png";
import OurServicesMap from "../../components/OurServiceMap";
import servicesLeft from "../../assets/servicesLeft.png";
import servicesRight from "../../assets/servicesRight.png";
import wine1 from "../../assets/wine1.png";
import wine2 from "../../assets/wine2.png";
import wine3 from "../../assets/wine3.png";
import wine4 from "../../assets/wine4.png";
import wine5 from "../../assets/wine5.png";
import sev1 from "../../assets/sev1.png";
import sev2 from "../../assets/sev2.png";
import Footer from "../../components/Footer";
import Navbar from "../../components/Navbar";

const ServicesPage = () => {
  return (
    <>
      <Navbar isWhite={true} />
      <HomeBackground>
        {window.screen.width > "801" && (
          <>
            <HomeImg src={statusImg}></HomeImg>
            <ServicesImg
              style={{ paddingTop: "7%", paddingBottom: "7%" }}
              src={towersImg}
            />
          </>
        )}
        {window.screen.width < "800" && (
          <>
            <img style={{ width: "100%" }} src={lastPic} alt="" />
          </>
        )}
      </HomeBackground>
      <HomeBackground style={{ backgroundColor: "#182029" }}>
        <ServicesContainer>
          <ServicesTextContainer>
            <OurServicesTitle>OUR SERVICES</OurServicesTitle>
            <OurServicesText>
              The Expertise of our team and people we work with will help ensure
              that your project is developed to meet your vision. To ensure we
              turn A vision into reality, we use our ground knowledge,
              professional research & creativity in which we offer the following
              services:
            </OurServicesText>
          </ServicesTextContainer>
          <OurServicesImg src={servicesTowerImg} />
        </ServicesContainer>
      </HomeBackground>
      <HomeBackground>
        <HomeImg
          style={{ filter: "brightness(50%)" }}
          src={serviceDeveloper}
        ></HomeImg>
        <Container style={{ padding: "10%" }}>
          <Row>
            <Col>
              <OurServicesTitle>
                SERVICES FOR <br />
                INVESTORS <br />& DEVELOPERS
              </OurServicesTitle>
            </Col>
            <Col>
              <OurServicesText style={{ width: "100%" }}>
                At Osoul Properties we appraise different project needs based on
                the client’s needs offering a holistic and personal approach to
                property management. We offer the following services{" "}
              </OurServicesText>
            </Col>
          </Row>
        </Container>
      </HomeBackground>
      <HomeBackground style={{ backgroundColor: "#182029" }}>
        <OurServicesMap
          fontColor="white"
          text1="ASSET MANAGEMENT"
          text2="PROPERTY MANAGEMENT"
          text3="OWNER's ASSOCIATION MANAGEMENT"
          text4="SALES AND LEASING"
          text5="FACILITIES MANAGEMENT"
        />
        <OurServicesMap
          fontColor="white"
          text1="REASEARCH"
          text2="CUSTOMER SERVICES"
          text3="REAL ESTATE MANAGEMENT ACCOUNTING"
          text4="CAPITAL MARKETS"
          text5="PROPERTY CONSULTION"
        />
      </HomeBackground>
      <HomeBackground style={{ paddingBottom: "10%" }}>
        <OverviewTitleContainer>
          <OverviewTitleImg src={servicesLeft} />
          <NeedTitle>YOUR NEED</NeedTitle>
          <OverviewTitleImg src={servicesRight} />
        </OverviewTitleContainer>
      </HomeBackground>
      <HomeBackground>
        <Container style={{ width: "50%" }}>
          <DetailsTitle>FIND YOUR SPACE</DetailsTitle>
          <DetailsText>
            Move into your first office/new home, find a new one with room to
            grow, or develop a complete location strategy.
          </DetailsText>
        </Container>
        <Container style={{ paddingBottom: "5%", paddingTop: "5%" }}>
          {window.screen.width > "801" && (
            <>
              <Row>
                <WineBox>
                  <WineBoxTitle>OFFICE</WineBoxTitle>
                  <WineBoxIcon src={wine1} />
                </WineBox>
                <WineBox>
                  <WineBoxTitle>hotels & hospitality </WineBoxTitle>
                  <WineBoxIcon src={wine2} />
                </WineBox>
                <WineBox>
                  <WineBoxTitle>RESIDENTIAL</WineBoxTitle>
                  <WineBoxIcon src={wine3} />
                </WineBox>
              </Row>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "stretch",
                }}
              >
                <WineBox>
                  <WineBoxTitle>mixed USE</WineBoxTitle>
                  <WineBoxIcon src={wine4} />
                </WineBox>
                <WineBox>
                  <WineBoxTitle>RETAIL</WineBoxTitle>
                  <WineBoxIcon src={wine5} />
                </WineBox>
              </Row>
            </>
          )}
          {window.screen.width < "800" && (
            <div style={{ width: "90%" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <WineBox>
                  <WineBoxTitle>OFFICE</WineBoxTitle>
                  <WineBoxIcon src={wine1} />
                </WineBox>
              </div>
              <Row
                style={{
                  display: "flex",
                  flexDirection: "row",
                  flexWrap: "nowrap",
                  justifyContent: "center",
                  alignItems: "center",
                  alignContent: "stretch",
                }}
              >
                <Col>
                  <Row>
                    <WineBox>
                      <WineBoxTitle>hotels & hospitality </WineBoxTitle>
                      <WineBoxIcon src={wine2} />
                    </WineBox>
                    <WineBox>
                      <WineBoxTitle>RESIDENTIAL</WineBoxTitle>
                      <WineBoxIcon src={wine3} />
                    </WineBox>
                  </Row>
                  <Row>
                    <WineBox>
                      <WineBoxTitle>mixed USE</WineBoxTitle>
                      <WineBoxIcon src={wine4} />
                    </WineBox>
                    <WineBox>
                      <WineBoxTitle>RETAIL</WineBoxTitle>
                      <WineBoxIcon src={wine5} />
                    </WineBox>
                  </Row>
                </Col>
              </Row>
            </div>
          )}
        </Container>
        <Container>
          <RowServicesFlex>
            <Col>
              <DetailsImg src={sev1} />
            </Col>
            <Col>
              <DetailsTitleBottom>Building Material Supply</DetailsTitleBottom>
              <DetailsTextBottom>
                We are one of the leading suppliers of high-quality tiling and
                flooring in the Middle East & Africa, offering collections from
                world-famous European brands. We specialize in floor and wall
                tiles of all kinds including mosaics, ceramic, porcelain,
                outdoor tiles, and swimming pools tiles. Also, sanitary ware
                including sinks, baths, and lavatories. Industries including
                residential and commercial projects to retails and individuals.{" "}
              </DetailsTextBottom>
            </Col>
          </RowServicesFlex>
        </Container>
        <Container style={{ paddingTop: "10%" }}>
          <RowServicesFlex>
            <Col>
              <DetailsTitleBottom>
                PROPERTY FEATURES & AMENITIES:
              </DetailsTitleBottom>
              <DetailsTextBottom>
                Property Management Demand more from your real estate. Go beyond
                cost and risk mitigation. We provide end to end property
                management services for all kinds of properties{" "}
              </DetailsTextBottom>
            </Col>
            <Col>
              <DetailsImg src={sev2} />
            </Col>
          </RowServicesFlex>
        </Container>
        <Container style={{ paddingTop: "10%", paddingBottom: "10%" }}>
          <RowFitout>
            <Col>
              <FitoutText>
                We offer interior fit outs for commercial, residential, retail
                and hospitality projects. We specialize in high end turnkey fit
                out projects within the commercial, retail, residential,
                hospitality, healthcare and F&B sectors. The company is managed
                by qualified and certified construction, contracts, project and
                cost management professionals. We encourage a prompt and
                pro-active ethos within all aspects of our fit-out business,
                adopting a very professional and transparent approach, at the
                initial tender design and build stages, and even throughout
                project execution, upon delivery, handover, and completion{" "}
              </FitoutText>
            </Col>
            <Col>
              <NewsLine />
            </Col>
            <Col>
              <FitoutTitle>FITOUT</FitoutTitle>
            </Col>
          </RowFitout>
        </Container>
      </HomeBackground>
      <HomeBackground style={{ paddingBottom: "10%" }}>
        <FitoutMap
          fontColor="black"
          text1="RESTAURANTS"
          text2="CAFES"
          text3="OFFICES"
          text4="PHARMICES"
        />
      </HomeBackground>
      <Footer />
    </>
  );
};

export default ServicesPage;
