import styled from "styled-components";

export const LandingPage = styled.div`
  background-color: red;
  width: 100%;
  height: 100vh;
`;
export const HomeBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const HomeImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(60%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  z-index: -1;
`;

export const NewsBoxDate = styled.p``;
export const NewsBoxContent = styled.p``;

export const HomeTitle = styled.h1`
  font-size: 2.8vw;
  text-align: center;
  color: white;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  padding: 20%;
  @media screen and (max-width: 730px) {
    font-size: 6vw;
    text-align: center;
    color: white;
    white-space: nowrap;
    z-index: 1;
    font-weight: 800;
    filter: brightness(100%);
    padding: 20%;
    padding-bottom: 90%;
    padding-top: 90%;
  }
`;

export const OverviewTitle = styled.h1`
  font-size: 400%;
  color: white;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  @media screen and (max-width: 800px) {
    font-size: 250%;
    padding-top: 30%;
  }
`;

export const OverviewTitleServices = styled.h1`
  font-size: 400%;
  color: white;
  text-align: center;
  white-space: nowrap;
  z-index: 1;
  font-weight: 800;
  filter: brightness(100%);
  @media screen and (max-width: 800px) {
    font-size: 200%;
    padding-top: 30%;
    white-space: normal;
    margin-top: 10%;
  }
`;
export const OverviewText = styled.p`
  font-size: 1vw;
  color: white;
  text-align: center;
  white-space: normal;
  z-index: 1;
  font-weight: 100;
  filter: brightness(100%);
  padding-top: 4%;
  padding-bottom: 4%;
  padding-left: 20%;
  padding-right: 20%;
  @media screen and (max-width: 800px) {
    font-size: 3vw;
    padding-left: 10%;
    padding-right: 10%;
  }
`;

export const OverviewButton = styled.button`
  color: white;
  background: #96191e;
  border-radius: 5px;
  border: 0px;
  z-index: 2;
  padding: 1%;
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
  margin-bottom: 20%;
  cursor: pointer;
  &:hover {
    background-color: Transparent;
    border: 2px solid white;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 100%;
  }
`;
export const OurServiceButton = styled.button`
  color: white;
  background: #96191e;
  border-radius: 5px;
  border: 0px;
  z-index: 2;
  padding: 1%;
  padding-left: 3%;
  padding-right: 3%;
  text-align: center;
  margin-bottom: 20%;
  cursor: pointer;
  &:hover {
    color: #96191e;
    background-color: Transparent;
    border: 2px solid #96191e;
  }
`;

export const OverviewTitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: flex-start;
  align-content: stretch;
  padding-top: 20%;
`;

export const OverviewTitleImg = styled.img`
  width: 100%;
  @media screen and (max-width: 800px) {
    width: 240%;
    margin-left: -85%;
    margin-right: 50%;
    margin-top: -20%;
    padding-left: 10%;
  }
`;

export const OverviewTitleImgServices = styled.img`
  width: 100%;
  @media screen and (max-width: 800px) {
    width: 450%;
    margin-left: -150%;
  }
`;
export const FeaturedImg = styled.img`
  width: 100%;
  filter: brightness(50%);
`;

export const NewsTitleLanding = styled.h1`
  font-size: 350%;
  color: white;
  padding-left: 30%;
  @media screen and (max-width: 800px) {
    padding-left: 0%;
  }
`;

export const OurServicesThreeContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  align-content: stretch;
`;

export const OurServicesThreeContent = styled.p`
  padding-left: 3%;
  padding-right: 3%;
  padding-top: 3%;
  white-space: nowrap;
  font-size: 100%;
  color: black;
  cursor: pointer;
  &:hover {
    color: #96191e;
    border-bottom: 1px solid #96191e;
  }

  @media screen and (max-width: 1360px) and (min-width: 1034px) {
    padding: 3%;
    white-space: nowrap;
    font-size: 85%;
  }
  @media screen and (max-width: 1033px) and (min-width: 800px) {
    padding: 2%;
    white-space: nowrap;
    font-size: 80%;
  }
`;

export const OurServicesText = styled.p`
  padding-top: 3%;
  font-size: 110%;
  text-align: center;
  @media screen and (max-width: 800px) {
    font-size: 80%;
    padding: 5%;
  }
`;

export const FeaturedLandingTitle = styled.h1`
  font-weight: 800;
  font-size: 200%;
  border-bottom: 2px solid #96191e;
  width: 30%;
  padding-bottom: 1%;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    width: 80%;
  }
`;

export const NewsBoxLanding = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  background-color: white;
  padding-top: 5%;
  padding-bottom: 5%;
  padding-left: 10%;
  padding-right: 10%;
  border-radius: 10px;
  @media screen and (max-width: 800px) {
    padding: 20%;
    border-radius: 10px;
  }
`;

export const NewsLandingContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-around;
  align-items: center;
  align-content: stretch;
  padding-top: 15%;
  padding-bottom: 15%;
  padding-right: 15%;
  padding-left: 0%;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-around;
    align-items: center;
    align-content: stretch;
    padding-top: 15%;
    padding-bottom: 15%;
    padding-right: 15%;
    padding-left: 15%;
  }
`;

export const NewsLine = styled.div`
  border-left: 1px solid #96191e;
  height: 250px;
  opacity: 1;
  z-index: 2;
  margin-right: 5%;
`;

export const DigitalContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;
  margin-bottom: -1.5%;

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
    margin-bottom: -1.5%;
  }
`;

export const DigitalTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: stretch;
  align-content: stretch;
  padding-top: 10%;
  padding-left: 10%;
  padding-right: 10%;
  @media screen and (max-width: 800px) {
    padding-bottom: 5%;
  }
`;
export const DigitalTextTitle = styled.h1`
  font-size: 150%;
`;
export const DigitalTextContent = styled.p`
  font-size: 100%;
`;
export const FeatureDesktop = styled.div`
  @media screen and (max-width: 800px) {
    display: none;
  }
`;
export const FeatureMobile = styled.div`
  @media screen and (min-width: 801px) {
    display: none;
  }
`;
