import styled from "styled-components";

export const AboutImg = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  filter: brightness(60%);
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
`;

export const WhyUsLearnButton = styled.button`
  margin-top: 2%;
  width: 20%;
  font-size: 1vw;
  padding: 1%;
  background-color: #96191e;
  color: white;
  border: 0;
  border-radius: 5px;
  &:hover {
    background-color: white;
    color: #96191e;
    border: 2px solid #96191e;
  }
  @media screen and (max-width: 800px) {
    font-size: 1.8vw;
    padding: 2%;
    width: 15vw;
  }
`;
export const LineImg = styled.img`
  position: absolute;
  width: 100%;
  height: 65%;
  z-index: 1;
  margin-bottom: 10%;
  @media screen and (max-width: 1300px) {
    width: 100%;
  }

  @media screen and (max-width: 1000px) {
    height: 50%;
  }

  @media screen and (max-width: 900px) {
    height: 40%;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const AboutBackground = styled.div`
  width: 100%;
  height: 100vh;
  background-color: #04062f;
  position: relative;

  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  z-index: 1;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    justify-content: center;
  }
`;

export const AboutMobile = styled.h1`
  z-index: 1;
  color: white;
  font-size: 8vw;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;
export const About = styled.h1`
  z-index: 1;
  color: white;
  font-size: 70px;
  margin-left: 6%;
  margin-top: 7%;

  @media screen and (max-width: 1600px) {
    font-size: 60px;
    margin-left: 5%;
    margin-top: 13%;
  }

  @media screen and (max-width: 1300px) {
    font-size: 60px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 50px;
  }

  @media screen and (max-width: 900px) {
    font-size: 40px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const AboutText = styled.div`
  z-index: 1;
  color: white;
  width: 60%;
  margin-top: -5%;
  margin-left: 12%;
  font-size: 25.5px;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 900px) {
    font-size: 10px;
  }

  @media screen and (max-width: 800px) {
    margin-top: 3%;
    text-align: center;
    margin-left: 0%;
    font-size: 15px;
  }
`;

export const BuildingImg = styled.img`
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: -7%;
  @media screen and (max-width: 1600px) {
    width: 600px;
  }

  @media screen and (max-width: 1300px) {
    width: 500px;
  }

  @media screen and (max-width: 1000px) {
    width: 400px;
  }

  @media screen and (max-width: 900px) {
    width: 400px;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const BuildingMobileImg = styled.img`
  margin-top: 3%;
  margin-bottom: 3%;
  margin-left: -7%;
  height: 40vw;
  @media screen and (min-width: 801px) {
    display: none;
  }
`;

export const AboutHeader = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  @media screen and (max-width: 800px) {
    height: 80vw;
  }
`;

export const WhyUsDiv = styled.div`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: flex-start;
  align-items: stretch;
  align-content: center;
  padding: 10%;
  @media screen and (max-width: 800px) {
    padding: 5%;
    margin-bottom: 5%;
  }
`;

export const WhyUs = styled.h1`
  font-size: 50px;

  @media screen and (max-width: 1300px) {
    font-size: 40px;
  }

  @media screen and (max-width: 1000px) {
    font-size: 30px;
  }

  @media screen and (max-width: 900px) {
    font-size: 20px;
  }
  @media screen and (max-width: 800px) {
    font-size: 30px;
    margin-bottom: 10%;
  }
`;

export const WhyUsDivText = styled.p`
  font-size: 25px;
  width: 95%;

  @media screen and (max-width: 1600px) {
    font-size: 20px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 15px;
  }

  @media screen and (max-width: 900px) {
    font-size: 15px;
  }
  @media screen and (max-width: 800px) {
    margin-bottom: 10%;
    height: 25vh;
  }
  @media screen and (max-width: 560px) {
    font-size: 2.5vw;
  }
`;

export const MapImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #182029;
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: cover;
  @media screen and (max-width: 1100px) {
    background-size: contain;
  }
  @media screen and (max-width: 800px) {
    background-size: cover;
  }

  @media screen and (max-width: 800px) {
    display: none;
  }
`;

export const MapMobileImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #182029;
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;

  @media screen and (min-width: 801px) {
    display: none;
  }

  @media screen and (max-width: 500px) {
    display: none;
  }
`;

export const MapSmallMobileImg = styled.div`
  width: 100%;
  height: 100vh;
  position: absolute;
  background-color: #182029;
  background-image: url(${(props) => props.src});
  color: white;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
  background-size: contain;

  @media screen and (min-width: 501px) {
    display: none;
  }
`;

export const DivMap = styled.div`
  z-index: 1;
  position: absolute;
  left: 140px;
  top: 25px;
  font-size: 3rem;
  border-bottom: 2px solid #96191e;

  @media screen and (max-width: 1600px) {
    font-size: 40px;
  }

  @media screen and (max-width: 1300px) {
    font-size: 30px;
    left: 100px;
    top: 100px;
  }

  @media screen and (max-width: 1200px) {
    font-size: 20px;
    left: 80px;
    top: 100px;
  }

  @media screen and (max-width: 800px) {
    font-size: 30px;
    left: 40px;
    top: 100px;
  }
`;

export const MajorProjectTitle = styled.div`
  margin-left: 3.5%;
  border-bottom: 2px solid #96191e;
  width: 50%;
  margin-bottom: 3%;
  margin-top: 3%;
  font-size: 3vw;

  @media screen and (max-width: 800px) {
    font-size: 4vw;
  }
`;

export const MajorProjectDiv = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  align-content: stretch;
`;
