import styled from "styled-components";

export const HomeBackground = styled.nav`
  width: 100%;
  height: auto;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
`;

export const DetailsImg = styled.img`
  width: 95%;
  height: 100%;
  @media screen and (max-width: 800px) {
    width: 100%;
    padding-top: 5%;
  }
  `;

export const DetailsTitle = styled.h1`
  font-weight: 800;
  font-size: 1.8vw;
  white-space: nowrap;
  border-bottom: 2px solid #96191e;
  width: 100%;
  padding-bottom: 3%;
  @media screen and (max-width: 800px) {
    font-weight: 800;
    font-size: 110%;
    white-space: nowrap;
    border-bottom: 2px solid #96191e;
    width: 100%;
    padding-bottom: 3%;
  }
`;

export const DetailsText = styled.p``;

export const DetailsSubTitle = styled.p`
  font-weight: 800;
  font-size: 1vw;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    font-weight: 800;
    font-size: 3.5vw;
    white-space: nowrap;
  }
`;

export const DetailsSubText = styled.p`
  font-weight: 100;
  font-size: 1vw;
  white-space: nowrap;
  @media screen and (max-width: 800px) {
    font-weight: 100;
    font-size: 3.5vw;
    white-space: nowrap;
  }
`;

export const PropertiesFlexColumn = styled.div`
  width: 100%;
`;
export const PropertiesFlexContainer = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: stretch;
  align-content: stretch;

  @media screen and (max-width: 800px) {
    padding: 5%;
    display: flex;
    flex-direction: column-reverse;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
`;

export const RowImages = styled.div`
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    justify-content: space-between;
    align-items: stretch;
    align-content: stretch;
  }
`;
