import {
  MajorDetail,
  MajorDetailDesc,
  MajorDetailDiv,
  MajorDetailTitle,
  MajorDiv,
  MajorImg,
} from "./MajorElement";

const MajorProject = (props) => {
  return (
    <MajorDiv>
      <MajorImg src={props.image} alt="" />
      <MajorDetail>
        <MajorDetailDiv>
          <MajorDetailTitle>Project Name</MajorDetailTitle>
          <MajorDetailDesc>Jumierah Luxury Living</MajorDetailDesc>
        </MajorDetailDiv>
        <MajorDetailDiv>
          <MajorDetailTitle>Location</MajorDetailTitle>
          <MajorDetailDesc>Masaar</MajorDetailDesc>
        </MajorDetailDiv>
        <MajorDetailDiv>
          <MajorDetailTitle>Developer</MajorDetailTitle>
          <MajorDetailDesc>Arada</MajorDetailDesc>
        </MajorDetailDiv>
        <MajorDetailDiv>
          <MajorDetailTitle>Type</MajorDetailTitle>
          <MajorDetailDesc>Residential Project</MajorDetailDesc>
        </MajorDetailDiv>
      </MajorDetail>
    </MajorDiv>
  );
};

export default MajorProject;
